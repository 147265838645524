<template>
<header class="header d-flex justify-content-between align-items-center mb-4">
    <a href="#" @click.prevent="" class="text-primary">
        <i class="mdi mdi-chevron-left font-big"></i>
    </a>
    <h5 class="">
        <span class="font-weight-bold font-big">04</span>
        <span class="font-big">/</span>
        <span class="font-big text-muted">04</span>
    </h5>
    <a href="#" class="">
        <i class="mdi mdi-menu font-big"></i>
    </a>
</header>
</template>

<script>
export default {

}
</script>

<style>

</style>