<template>
  <div class="pb-5 mb-5">
        <preferences
            type="business"
            :heading="{
                title: `Vendor <span>Preferences(s)</span>?`,
                description: 'Select preferences for all vendors',
            }"
            v-model="prefs"
            class="mt-2"/>

        <h5 class="mb-4">What rating?</h5>

        <div class="vendor-rating mb-5 ml-3">
            <button
                type="button"
                @click="setRating('low')"
                class="rating"
                :class="{ 'active': value.rating == 'low' }">
                low</button>
            <button
                type="button"
                @click="setRating('medium')"
                class="rating"
                :class="{ 'active': value.rating == 'medium' }"
                >med</button>
            <button
                type="button"
                @click="setRating('high')"
                class="rating"
                :class="{ 'active': value.rating == 'high' }">
                high</button>
        </div>
    </div>
</template>

<script>
import Preferences from './preferences.vue';

export default {
    props: {
        value: {
            type: Object,
            default: () => ({
                prefs: [],
                rating: ''
            }),
        },
    },
    components: {
        Preferences
    },
    data() {
        return {
            prefs: []
        }
    },
    watch: {
        prefs: {
            handler() {
                this.$emit('input', {
                    ...this.value,
                    prefs: this.prefs
                });
            },
            deep: true
        }
    },
    methods: {
        setRating(rating) {
            this.$emit('input', {
                ...this.value,
                rating
            });
        }
    }
}
</script>

<style>

</style>