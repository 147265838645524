<template>
    <div>
        <my-holdlists
            :holdLists="holdLists"
            type="friends"/>
    </div>
</template>

<script>
import MyHoldlists from './MyHoldlists.vue';
export default {
    props: ['holdLists'],

    components: {
        MyHoldlists
    }
}
</script>

<style>

</style>