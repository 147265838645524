<template>
    <div class="status-interests no-wrap pl-0 pr-2 no-scrollbar mb-3">
        <button
            type="button"
            class="interest-item elevation-2"
            :class="{'active': !value}"
            @click="$emit('input', '')">
            <div class="img">
                <img src="/img/all.png"/>
            </div>
            <span class="text-muted name">All Interests</span>
        </button>

        <button
            type="button"
            class="interest-item elevation-2"
            v-for="(interest, index) in interests"
            :key="index"
            :class="{'active': value == interest.id}"
            @click="$emit('input', interest.id)">
            <component :is="interest.icon"/>
            <span class="text-muted name">
                {{ interest.name }}
            </span>
        </button>

        <button
            @click.prevent="addMore"
            type="button"
            class="interest-item elevation-2">
            <div class="img">
                <img src="/img/plus.png"/>
            </div>
            <span class="text-muted name">Add more</span>
        </button>
    </div>
</template>

<script>
import Icons from '@/components/icons';

export default {
    props: ['value'],

    components: {
        ...Icons
    },

    computed: {
        interests() {
            if (!this.authUser.interests) return []

            return this.authUser.interests
            .map(item => item.interest_type)
            .filter(item => !!item)
            .sort((a, b) => {
                if (a.name < b.name) return -1;

                return 1;
            });
        }
    },

    methods: {
        addMore() {
            this.$router.push({name: 'select_interests', query: {mul: 1}})
        }
    }
}
</script>