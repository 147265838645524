<template>
    <Products
        :ads="ads"
        :pageDetails="pageDetails"
        @next-page="loadUpdates"
        @viewAd="viewAd"
        @trashed="onTrashed"
        :status="status" />
</template>

<script>
import Products from '@/views/customer/products/Index.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['intent_id'],

    components: {
        Products,
    },

    data() {
        return {
            status: 'new',
        }
    },

    computed: {
        ...mapGetters({
            ads: 'Statuses/getAll',
            pageDetails: 'Statuses/getPageDetails'
        }),

        mood() {
            return this.$route.params.mood;
        },

        productTypeIds() {
            return this.$route.params.ids;
        }
    },

    methods: {
        ...mapActions({
            index: 'Statuses/index',
            removeUpdate: 'Statuses/remove'
        }),

        viewAd(id) {
            this.$router.push({ name: 'product_detail', params: { id } })
        },

        loadUpdates(query) {
            let params = {
                ...query,
                status: this.status,
                type: 'intent',
                intent_id: this.intent_id
            }

            if (params.clearState) {
                this.showLoader(`Getting ${this.status} updates... Please wait`);
            }

            this.index(params)
                .then(response => {
                    this.hideLoader();
                    this.readImpressions();
                })
                .catch(error => {
                    this.hideLoader();
                })
        },


        loadNextPage() {
            if (this.showLoadMore) {
                this.loadUpdates(this.loadMore);
            }
        },

        onTrashed() {
            this.status = 'viewed'
            this.loadUpdates({ clearState: true})
        }
    },

    created() {
        this.loadUpdates({ clearState: true });
    }
}
</script>

<style></style>