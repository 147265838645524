<template>
    <div class="pb-5">
        <div class="heading">
            <h3 class="title">Product <span>Feature(s)</span>?</h3>
            <p class="mb-3 description text-muted text-center">
                Are there specific product features that you are interested? Enter a keyword for each feature e.g. Touchscreen and hit the &lt;Return&gt; key.
            </p>
        </div>

        <div v-for="(product, index) in productTypes" :key="index">
            <base-token-input
                :label="product.name"
                name="keywords"
                placeholder="e.g. Touchscreen"
                v-model="value[product.id]"
                :shouldSubmit="true"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },

        productTypes: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        init() {
            const vals = { ...this.value };

            for (let productType of this.productTypes) {
                vals[productType.id] = vals[productType.id] ? vals[productType.id] : [];
            }

            this.$emit('input', { ...vals });
        },
    },

    created() {
        this.init();
    },
}
</script>

<style>

</style>