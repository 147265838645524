<template>
     <responsive-width>
        <view-updates type="interest"/>
     </responsive-width>
</template>
<script>
import ViewUpdates from './ViewUpdates.vue';

export default {
    components: {
        ViewUpdates
    },

    created() {
        if (!this.authUser.interests || !this.authUser.interests.length) {
            return this.$router.replace({name: 'select_interests'});
        }
    }
}
</script>