<template>
    <div class="form-group" :class="`${divClass} type-${typeNumber}`">
        <div class="icon">
            <span :class="`${icon} ${error ? 'shake-bell text-danger' : ''}`"></span>
        </div>

        <label class="font-xs" style="margin-bottom:0 !important;" v-if="!noLabel">
            {{label ? label : placeholder}}

            <a
                v-if="tooltip"
                href="#"
                @click.prevent="showTooltip">
                <span class="mdi mdi-help-circle"></span>
            </a>

            <small class="font-8 text-muted" v-if="required">(Required)</small>
        </label>

        <slot></slot>

        <span class="font-xs error-label" v-if="error">{{ error }}</span>
    </div>
</template>

<script>

export default {
    props: {
        value: {
            type: [String, Number]
        },

        typeNumber: {
            type: [String, Number],
            default: 4
        },

        required: {
            type: Boolean,
            default: false
        },

        name: {
            type: String
        },

        isTextInput: {
            type: Boolean,
            default: true
        },

        type: {
            type: String,
        },

        label: {
            type: String,
            default: ''
        },

        error: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        noLabel: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        reference: {
            type: String
        },

        divClass: {
            type: String
        },

        desc: {
            type: String
        },

        outline: {
            type: Boolean,
            default: false
        },

        tooltip: {
            type: Boolean,
            default: false
        },

        help: {
            type: Array
        },

        icon: {
            type: String
        }
    },

    data () {
        return {
            payload: ''
        }
    },

    methods: {
        /**
         * Emits typed value
         *
         * @param {Object} $event
         * @return {Undefined}
         */
        onInput($event) {
            this.$emit('input', $event.target.value);
            this.$emit('change');
        },

        showTooltip() {
            if (!this.help) {
                this.$emit('help', this.name);
            }
        }
    }
}
</script>

<style>

</style>
