<template>
    <question-answer>
        <question>
            <div ref="title" class="font-5xl font-thin text-muted"></div>
        </question>

        <answers alignItems="start" :showOptions="showOptions">
            <div class="interest-list row">
                <div
                    v-for="(type, index) in interests"
                    :key="index"
                    @click.prevent="addInterest(type)"
                    class="col-12 col-md-3 interest-item btn-block full-height"
                    :class="{ 'selected': ids.includes(type.id) }">
                        <span class="index font-xs">{{ index + 1 }}</span>
                        <component class="icon elevation" :is="type.icon"/>
                        <div class="name font-small text-capitalize text-truncate">{{ type.name }}</div>
                        <i class="mdi mdi-checkbox-marked-circle check font-3xl"></i>
                </div>
            </div>

            <div class="interests-actions">
                <lets-go @click="goNext" text="Get Started" />
            </div>
        </answers>
    </question-answer>
</template>

<script>
import TypeIt from "typeit";
import Icons from '@/components/icons';
import LetsGo from '@/views/customer/getting-started/steps/LetsGo.vue';
import Answers from '@/views/customer/getting-started/steps/partials/Answers.vue';
import Question from '@/views/customer/getting-started/steps/partials/Question.vue';
import QuestionAnswer from '@/views/customer/getting-started/steps/partials/QuestionAnswer.vue';

export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
        interests: {
            type: Array,
            default: () => []
        }
    },

    components: {
        ...Icons,
        Answers,
        Question,
        QuestionAnswer,
        LetsGo,
    },

    data() {
        return {
            showOptions: false,
        }
    },

    computed: {
        ids() {
            return this.value.map(i => i.id);
        }
    },

    methods: {
        removeInterest(id) {
            this.$emit('input', this.value.filter(i => i.id != id));
        },

        addInterest(val) {
            if (this.value.find(i => i.id == val.id)) {
                this.removeInterest(val.id);
                return;
            }

            this.$emit('input', [...this.value, val]);
        },

        typeHeading() {
            const focus = `<span class="font-5xl text-primary">least 5 interests</span>`;

            const instance = new TypeIt(this.$refs.title, {
                speed: 20,
                startDelay: 100,
                cursor: false,
                afterComplete: async (instance) => {
                    this.showOptions = true;
                },
            })
                .empty()
                .type(`Select at ${focus} or hobbies to get started.`)
                .type(`<p class="font-small mt-2 font-italic text-black-fade">Scroll to bottom when done.</p>`)
                .go()
        },

        goNext() {
            if (this.value.length === 0) return;

            this.$emit('get-started');
        }
    },

    mounted() {
        setTimeout(() => {
            this.typeHeading();
        }, 0);
    }
}
</script>