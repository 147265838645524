<template>
    <div class="">

        <div class="add-form mb-md-3" id="add-form" ref="addForm">
            <div class="row justify-content-center align-items-center">
                <div class="col-md-10 col-12 mb-3">
                    <div class="heading">
                        <h3 class="title">Which <span>Products</span>?</h3>
                        <p class="mb-3 description text-muted text-center">
                            Enter keyword for a product type <i>(e.g. laptops)</i>.
                            Select nearest category from list shown below to add to channel.
                        </p>
                    </div>

                    <base-input
                        :noLabel="true"
                        typeNumber="0"
                        v-model="keyword"
                        icon="mdi mdi-magnify"
                        placeholder="e.g laptop, shoes"/>
                </div>
            </div>
        </div>

        <div class="product-types-list selected mb-3" v-if="value.length > 0">
            <div class="" v-for="(productType, index) in value" :key="index">
                <div class="product-type-item elevation-2"
                    @click="remove(productType)">
                    <div class="name font-small text-primary text-truncate">
                        {{ productType.name }}
                    </div>
                </div>
            </div>
        </div>

        <div class="product-types-list" v-if="productTypes.length > 0">
            <div class="" v-for="(cat, index) in productTypes" :key="index">
                <div class="" v-for="(productType, ind) in cat" :key="ind">
                    <div class="product-type-item elevation-2"
                        @click="add(productType)">
                        <div class="">
                            <div class="name font-small text-primary text-truncate">
                                {{ productType.name }}
                            </div>
                            <p class="category m-0 text-muted text-truncate">
                                <span class="font-xs" v-if="productType.parent">
                                    > {{ productType.parent.name }}
                                </span>
                                <span class="font-xs" v-else>Main category</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { chunk } from 'lodash';

export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        }
    },
    components: {
    },

    data() {
        return {
            keyword: '',
        }
    },

    computed: {
        ...mapGetters({
            product_types: 'ProductTypes/getAll',
        }),

        productTypes() {
            if (!this.keyword) return [];

            let parts = this.keyword.toLowerCase().split(' ').filter(part => part.trim());
            const selectedIds = this.value.map(item => item.id);
            let product_types = this.product_types.filter(item => !selectedIds.includes(item.id));

            for (let part of parts) {
                product_types = product_types.filter(item => {
                    const names = item.name.toLowerCase().split(' ')

                    for (let name of names) {
                        if (name.startsWith(part)) return true;
                    }
                })
            }

            return chunk(product_types, 3);
        }
    },

    methods:{
        ...mapActions({
            checkDuplicates: 'Intents/checkDuplicates',
        }),

        remove(productType) {
            this.$emit('input', this.value.filter(item => item.id !== productType.id));
        },

        add(productType) {
            if (this.value.length >= 5) {
                this.swalError(`You can only add 5 product types.`)
                return;
            }

            this.$emit('input', [productType, ...this.value]);
        },
    },
}
</script>