import { axios } from './index';

export default {
    /**
     * Get available holdlists
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('hold_lists', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    show(id, params) {
        return axios.get(`hold_lists/${id}`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    update(id, data) {
        return axios.put(`hold_lists/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    getAnalytics(params) {
        return axios.get('hold_lists/analytics', {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    getConnections(params) {
        return axios.get('hold_lists/connections', {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    shareHoldList(data) {
        return axios.post('hold_lists/shares', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    findByPublicId({public_id}) {
        return axios.get(`/hold_lists/public/${public_id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },
}