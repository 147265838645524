<template>
  <div>
    <view-hold-list
        :hold_list_id="holdListId"
        :guest="email"
        v-if="showViewPage"
    />

    <popup-dialog noSpaceTop v-if="showPopup">
        <div class="shlguest p-5">
            <div class="logo">
                <logo/>
            </div>

            <div class="form">
                <p class="text-black m-0 font-small">
                    Please enter your email to view this page
                </p>

                <base-input
                    type="email"
                    placeholder="e.g username@email.com"
                    v-model="email"
                    :error="errorMsg"/>

                <btn-with-border-gradient class="mt-5" @click="validateEmail">
                    <span class="font-small">View Hold List</span>
                </btn-with-border-gradient>
            </div>
        </div>
    </popup-dialog>
  </div>
</template>

<script>
import ViewHoldList from '@/views/customer/holdlists/View.vue';
import PopupDialog from '@/components/shared/PopupDialog.vue';
import { mapActions } from 'vuex';

export default {
    props: ['public_id'],

    components: {
        ViewHoldList,
        PopupDialog,
    },

    data() {
        return {
            holdListId: '',
            holdListUserId: '',
            email: '',

            showViewPage: false,
            showPopup: false,
            errorMsg: ''
        }
    },

    methods: {
        ...mapActions({
            getHoldList: 'HoldLists/findByPublicId',
            checkUserExists: 'Users/checkUserExists',
        }),

        validateEmail() {
            this.errorMsg = '';

            if (this.email) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (emailRegex.test(this.email)) {
                    this.showLoader(`Processing... Please wait`);

                    this.checkUserExists({
                        email: this.email
                    }).then(user => {
                        console.log(user);
                        this.hideLoader();

                        this.swalConfirm(
                            `Account exists`,
                            `An account with this email already exists. Please login to view this page or change the email address to continue. Do you want to login now?`, `Login now`, `Change Email`
                        ).then(result => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'view_holdlist',
                                    params: {
                                        hold_list_id: this.holdListId,
                                        type: this.holdListUserId === user.id ? 'self' : 'friends'
                                    }
                                });
                            }
                        });
                    }).catch(error => {
                        this.hideLoader();
                        this.showPopup = false;
                        this.showViewPage = true;
                        this.updateRouteQuery({ gst: this.email });
                    });
                } else {
                    this.errorMsg = 'Please enter a valid email address';
                }
            } else {
                this.errorMsg = 'Please enter your email address';
            }
        },

        redirect() {
            if (this.isLoggedIn) {
                this.$router.push({
                    name: 'view_holdlist',
                    params: {
                        hold_list_id: this.holdListId,
                        type: this.holdListUserId === this.authUser.id ? 'self' : 'friends'
                    }
                });
            } else {
                this.showPopup = true;
            }
        },

        loadData() {
            this.showLoader(`Loading data... Please wait`);
            this.getHoldList({
                public_id: this.public_id
            }).then(response => {
                this.hideLoader();

                this.holdListId = response.id;
                this.holdListUserId = response.user_id;

                this.updateRouteQuery({
                    hlid: response.id,
                    hluid: response.user_id
                });

                this.redirect();
            }).catch(error => {
                this.hideLoader();
            })
        }
    },

    created() {
        const hlid = this.$route.query.hlid;
        const gst = this.$route.query.gst;
        const hluid = this.$route.query.hluid;

        if (hlid) {
            this.holdListId = parseInt(hlid);
        }

        if (hluid) {
            this.holdListUserId = parseInt(hluid);
        }

        if (gst) {
            this.email = gst;
        }

        if (this.holdListId && this.holdListUserId) {
            this.redirect();

            if (this.email) {
                this.showPopup = false;
                this.showViewPage = true;
            }
        } else {
            this.loadData();
        }
    }
}
</script>

<styl
Viewe>

</style>