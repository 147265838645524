export default {
    getLoader(state) {
        return state.loader;
    },

    /**
     * Get pagination parameters
     *
     * @param {Object} state
     * @returns {Object}
     */
    getPagination(state) {
        return state.pagination;
    },

    getSidebarOpened(state) {
        return state.sidebarOpened;
    },

    getColors(state) {
        return state.colors;
    },

    getPHeaderTitle(state) {
        return state.pHeaderTitle;
    }
}