import Config from '@/config';
import filters from './filters';
import methods from './methods';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			dateFormat: 'ddd Do MMM, YYYY'
		}
	},

	computed: {
		...mapGetters({
			authUser: 'Authentications/getUser',
			isLoggedIn: 'Authentications/isLoggedIn',
			pagination: 'getPagination'
		}),

		loadMore() {
			return { page: this.pageDetails.currentPage + 1 }
		},

		showLoadMore() {
			return this.pageDetails.currentPage < this.pageDetails.lastPage;
		}
	},

	filters,

	methods
}