<template>
    <div class="bottom-tab boxed">
        <div class="content font-xs">
            <a
                href="#"
                v-for="(item, index) in items"
                @click.prevent="$emit('input', item.id)"
                :class="{'active': value == item.id}"
                :key="index">
                <span>
                    {{ item.name }}
                    <span class="badge badge-danger ml-1" v-if="item.showBadge">
                        {{ item.badge }}
                    </span>
                </span>

                <p class="m-0 font-weight-normal font-xxs text-muted desc">
                    {{ item.description }}
                </p>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: ['items', 'value']
}
</script>