<template>
<div class="p-header">
    <button type="button" class="btn ph-back" @click.prevent="$router.back()">
        <i class="mdi mdi-chevron-left"></i>
    </button>
    <h1 class="ph-title">{{ _title || title }}</h1>

    <div class="ph-account">
        <i class="fa fa-caret-down mr-1" v-if="isLoggedIn"></i>

        <img :src="imageUrl || userImage" class="circle"/>

        <div class="options" v-if="isLoggedIn">
            <router-link :to="{name: 'settings'}" class="option-item font-xs text-truncate" href="#">
                <i class="fa fa-cogs mr-1"></i> Settings
            </router-link>
            <router-link :to="{name: 'notifications'}">
                <a class="option-item font-xs text-truncate" href="#">
                    <i class="fa fa-bell-o mr-1"></i> Notifications
                </a>
            </router-link>
            <a class="option-item font-xs text-truncate" href="#" @click.prevent="logout"><i class="fa fa-lock mr-1"></i> Logout</a>
        </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import bus from '@/event-bus'

export default {
    props: [
        '_title'
    ],

    data() {
        return {
            imageUrl: null
        }
    },

    computed: {
        ...mapGetters({
            title: 'getPHeaderTitle'
        }),

        userImage() {
            if (this.isLoggedIn) {
                return this.authUser.image_url;
            }

            return '/img/user.jpg'
        },
    },

    methods: {
        ...mapActions({
            authLogout: 'Authentications/logout'
        }),

        logout() {
            this.showLoader('logging out... Please wait');

            this.authLogout()
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                });
        },

        setTitle(title) {
            this.title = title;
        },

        setImage(imageUrl) {
            this.imageUrl = imageUrl;
        }
    }
}
</script>

<style>

</style>