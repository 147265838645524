import Service from '@/services/users.js';

const state = {
    users: []
};

const getters = {
    getUsers(state) {
        return state.users;
    },
};

const mutations = {
    SET_USERS(state, payload) {
        state.users = [...payload];
    },
};

const actions = {
    searchUsers({ commit }, params) {
        return Service.searchUsers(params)
            .then(response => {
                commit('SET_USERS', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    checkUserExists({ commit }, params) {
        return Service.checkUserExists(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}