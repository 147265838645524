import Service from '@/services/adverts';

const state = {
    all: [],
    pageDetails: {
        to: 0,
        from: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        currentPage: 0,
    },

    attributeValues: [],
    callToActionTypes: [],
    offerCategories: [],
    offerShippings: [],
};

const getters = {
    getAll(state) {
        return state.all;
    },

    getPageDetails(state) {
        return state.pageDetails;
    },

    getAttributeValues(state) {
        return state.attributeValues;
    },

    getCallToActionTypes(state) {
        return state.callToActionTypes;
    },

    getOfferCategories(state) {
        return state.offerCategories;
    },

    getOfferShippings(state) {
        return state.offerShippings;
    }
};

const mutations = {
    SET(state, payload) {
        state.pageDetails = {
            total: payload.total || 0,
            from: payload.from || 0,
            to: payload.to || 0,
            currentPage: payload.current_page || 0,
            lastPage: payload.last_page || 0,
            perPage: payload.per_page || 0
        }

        state.all = payload.data ? payload.data : payload;
    },

    SET_ATTRIBUTE_VALUES(state, payload) {
        state.attributeValues = payload;
    },

    SET_CALL_TO_ACTION_TYPES(state, payload) {
        state.callToActionTypes = payload;
    },

    SET_OFFER_CATEGORIES(state, payload) {
        state.offerCategories = payload;
    },

    SET_OFFER_SHIPPINGS(state, payload) {
        state.offerShippings = payload;
    }
};

const actions = {
    index({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET', response);
                return Promise.resolve(response);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    store({ commit }, data) {
        return Service.store(data)
            .then(response => {
                commit('Adverts/SET', response, { root: true });
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    update({ commit }, params) {
        return Service.update(params.id, params.data)
            .then(response => {
                commit('Campaigns/UPDATE_ADVERT', response, {root: true})

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    show({ commit }, id) {
        return Service.show(id)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    showGettingStartedAd({ commit }, id) {
        return Service.showGettingStartedAd(id)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    storePoll({ commit }, data) {
        return Service.storePoll(data)
            .then(response => {
                // commit('SET_POLLS', response)
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error));
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}