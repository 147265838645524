<template>
  <div class="create-new-channel">
    <p class="title1">
        {{ mood == 'intent' ? `Don't have a channel?` : `Don't have an account?` }}
    </p>
    <h2 class="title2">Create new</h2>
    <p class="text-muted">
        {{ mood == 'explore' ? `Later you can create` : `Create` }}
         your shopping channel to invite merchants offers and manage your shopping journey
    </p>

    <div class="border-wrap create-channel-info">
        <router-link :to="{name: 'create_channel', params: $route.params}">
            <button type="button" class="btn create-account btn-block">
                Create {{ mood == 'intent' ? `Channel` : `Account` }}
            </button>
        </router-link>
    </div>
</div>
</template>

<script>
export default {
    props: ['mood'],

    computed: {
        createChannelRoute() {
            return {
                name: 'create_channel',
                params: this.$route.params
            }
        }
    }
}
</script>

<style>

</style>