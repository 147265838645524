<template>
  <div class="border-wrap" :class="{inline: inline}">
        <button
            type="submit"
            @click="$emit('click', $event)"
            class="btn btn-block">
            <slot/>
        </button>
    </div>
</template>

<script>
export default {
    props: ['content', 'inline']
}
</script>

<style>

</style>