<template>
    <responsive-width>
        <div class="mt-2 d-flex flex-nowrap">
            <span
                class="token font-xs p-2 m-1 round text-center bg-default-dark text-primary d-flex align-items-center"
                v-for="(type, index) in productTypes" :key="index">
                {{ type.name }}
            </span>
        </div>

        <div class="mt-3">
            <view-ads :intent_id="intent_id"/>
        </div>

        <!-- <bottom-nav/> -->
    </responsive-width>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ViewAds from '@/views/customer/intents/view/Ads.vue';

export default {
    props: ['intent_id'],

    components: {
        ViewAds,
    },

    data() {
        return {
            intent: null
        }
    },

    computed: {
        productTypes() {
            if (!this.intent || !this.intent.product_types) return [];

            return this.intent.product_types;
        },
    },

    methods: {
        ...mapActions({
            show: 'Intents/show',
        }),

        getIntent() {
            this.showLoader(`Getting intent details... Please wait`);

            this.show(this.intent_id)
                .then(response => {
                    this.hideLoader();
                    this.intent = response;
                    this.setPheaderTitle(this.intent.name);
                })
                .catch(error => {
                    this.hideLoader();
                    console.log(error);
                })
        }
    },

    created() {
        this.setPheaderTitle('Intent Details');
        this.getIntent();
    }
}
</script>