<template>
  <li class="glide__slide">
    <slot/>
  </li>
</template>

<script>
export default {

}
</script>

<style>

</style>