<template>
    <div class="lets-go row justify-content-center">
        <div class="col-6 col-md-2 p-0">
            <button @click="$emit('click')" class="btn btn-block font-small elevation-2" type="button">
                {{ text }} <i class="mdi mdi-chevron-right font-big"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: 'Let\'s Go'
        },
        textClass: {
            type: String,
        }
    }
}
</script>

<style>

</style>