<template>
    <question-answer>
        <question>
            <div ref="title" class="font-5xl font-thin text-muted"></div>
        </question>


        <answers :showOptions="showOptions">
            <div
                v-for="btn in btns"
                :key="btn.index"
                class="answer-option d-flex align-items-center justify-content-between"
                @click="selectMood(btn)">
                <i class="mr-3 font-big icon" :class="btn.icon"></i>
                <div
                    class="flex-grow-1 text text-shadow font-medium"
                    v-html="btn.text">
                </div>
                <i class="font-big mdi mdi-chevron-right icon ml-2"></i>
            </div>
        </answers>
    </question-answer>
</template>

<script>
import TypeIt from "typeit";
import Answers from '@/views/customer/getting-started/steps/partials/Answers.vue';
import Question from '@/views/customer/getting-started/steps/partials/Question.vue';
import QuestionAnswer from '@/views/customer/getting-started/steps/partials/QuestionAnswer.vue';

export default {
    components: {
        Answers,
        Question,
        QuestionAnswer
    },

    data() {
        return {
            showOptions: false,
            btns: [
                {
                    index: 2,
                    icon: 'mdi mdi-target',
                    text: 'I have a specific Shopping Intent',
                    mood: 'intent'
                },
                {
                    index: 4,
                    icon: 'mdi mdi-eye-plus-outline',
                    text: 'Just window shopping',
                    mood: 'explore'
                }
            ]
        }
    },

    methods: {
        typeHeading() {
            const objective = `<span class="font-5xl text-primary">shopping mood</span>`;

            new TypeIt(this.$refs.title, {
                speed: 20,
                startDelay: 100,
                cursor: false,
                afterComplete: async (instance) => {
                    this.showOptions = true;
                },
            })
                .empty()
                .type(`What is your ${objective} today?`)
                .go();
        },

        selectMood(btn) {
            this.$emit('next', btn.index);
            this.$emit('setMood', btn.mood);
        }
    },

    mounted() {
        setTimeout(() => {
            this.typeHeading();
        }, 0);
    }
}
</script>