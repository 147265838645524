<template>
    <question-answer>
        <question>
            <div ref="title" class="font-5xl font-thin text-muted"></div>
        </question>


        <answers :showOptions="showOptions">
            <div class="objective-form">
                <base-input
                    noMargin
                    class="m-0"
                    placeholder="e.g. improving sleep"
                    v-model="objective"
                  />

                <div class="mt-5">
                    <lets-go @click="goNext" text="Next"/>
                </div>
            </div>
        </answers>
    </question-answer>
</template>

<script>
import TypeIt from "typeit";
import Answers from '@/views/customer/getting-started/steps/partials/Answers.vue';
import Question from '@/views/customer/getting-started/steps/partials/Question.vue';
import QuestionAnswer from '@/views/customer/getting-started/steps/partials/QuestionAnswer.vue';
import LetsGo from '@/views/customer/getting-started/steps/LetsGo.vue';

export default {
    props: {
        value: {
            type: String,
            default: ''
        }
    },

    components: {
        Answers,
        Question,
        QuestionAnswer,
        LetsGo
    },

    data() {
        return {
            showOptions: false,
            objective: ''
        }
    },

    watch: {
        value() {
            this.objective = this.value;
        },

        objective() {
            this.$emit('input', this.objective);
        }
    },

    methods: {
        typeHeading() {
            const objective = `<span class="font-5xl text-primary">objective</span>?`;

            const instance = new TypeIt(this.$refs.title, {
                speed: 20,
                startDelay: 100,
                cursor: false,
                afterComplete: async (instance) => {
                    this.showOptions = true;
                },
            })
            .empty()
            .type(`What is your <br/> overall shopping <br/>`);

            if (Math.floor(Math.random() * 2)) {
                instance.type(`objective?`)
                        .delete(10, { speed: 200 })
                        .type(objective)
            } else {
                instance.type(objective);
            }

            instance.go();
        },

        goNext() {
            if (!this.objective) return;

            this.$emit('next', 3);
        }
    },

    mounted() {
        setTimeout(() => {
            this.typeHeading();
        }, 0);
    }
}
</script>