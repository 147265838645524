<template>
    <div class="set-ranking-item">
        <div class="media">
            <img ref="currentImg" :src="advert.cover_media.file_url"/>
        </div>
        <div class="features">
            <div class="ranking btn-group" v-if="isHoldlistOwner">
                <button
                    type="button"
                    class="btn text-truncate font-small"
                    :class="{active: rank === n}"
                    v-for="n in 3"
                    :key="n"
                    @click="$emit('setRank', n)">
                    Rank {{ n }}
                </button>
            </div>

            <div class="feature" v-for="(feature, index) in features" :key="index">
                <i class="icon mdi mdi-checkbox-multiple-marked-circle-outline"></i>
                <span class="feature-value font-small text-truncate">
                    {{ feature }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';

export default {
    props: {
        holdListItem: {
            type: Object,
            required: true
        },
        rank: {
            type: Number,
            default: 0,
            required: true
        },
        isHoldlistOwner: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        advert() {
            return this.holdListItem && this.holdListItem.advert;
        },

        features() {
            const features = [];
            const attributes = get(this.advert, 'values', []);
            const fields = ['brand', 'color', 'material', 'price', 'model'];

            for (let attribute of attributes) {
                if (fields.includes(attribute.name) && attribute.value) {
                    let value = attribute.value;

                    if (attribute.name === 'price') {
                        value = `$${value}`
                    }

                    features.push(value);
                }
            }

            return features;
        }
    },
    mounted() {
    }
}
</script>

<style>

</style>