<template>
  <div class="pb-5">
        <div class="heading">
            <h3 class="title">Which <span>Brand(s)</span>?</h3>
            <p class="mb-3 description text-muted text-center">
                Select brand for each product
            </p>
        </div>

        <div class="mb-4" v-for="(product, index) in productTypes" :key="index">
            <base-input
                noMargin
                :label="product.name"
                class="m-0"
                :placeholder="`Brand for ${product.name} e.g Apple`"
                reference="brand"
                v-model="value[product.id]"
                :typeahead="brandConfig"
                @selection="val => setValue(product.id, val.name)"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        productTypes: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        brandConfig() {
            return {
                url: `intent_attributes/search_values/brands`,
                options: {
                    name: 'brand',
                    display: 'name'
                }
            };
        },
    },

    methods: {
        setValue(id, name) {
            this.$emit('input', {
                ...this.value,
                [id]: name
            })
        },

        initBrands() {
            for (let productType of this.productTypes) {
                this.setValue(productType.id, this.value[productType.id] || '');
            }
        }
    },

    created() {
        this.initBrands();
    },
}
</script>

<style>

</style>