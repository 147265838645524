<template>
  <div>
    <div class="mb-3 elevation p-2 m-1 rounded" v-if="values.length">
      <h5 class="font-big text-primary text-center p-2">A chart showing the total comments for the ads</h5>
      <div class="d-flex align-items-center">
        <div class="pie-container mr-3">
          <canvas id="chart" ref="piechart"></canvas>
        </div>
        <div class="labels" v-if="chart">
          <div class="label" v-for="(legend, index) in legends" :key="index">
            <div class="color"
              :style="{ backgroundColor: legend.color }">
            </div>

            <div class="font-small text-truncate">{{ legend.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createPieChart, setActivePieChartSlice } from '@/libs/chart';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    holdList: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chart: null,
      labels: [],
      values: [],
      dataLebels: [],
      activeIndex: -1,
    }
  },

  computed: {
    ...mapGetters({
      holdListAnalytics: 'HoldLists/getAnalytics'
    }),

    legends() {
      return this.labels.map((label, index) => {
        let color = 'transparent';
        if (this.chart && this.chart.data.datasets.length > 0) {
          color = this.chart.data.datasets[0].backgroundColor[index];
        }
        return { label, color };
      });
    },

    selectedAnalytics() {
      if (this.activeIndex === -1) return null;

      return this.holdListAnalytics[this.activeIndex];
    }
  },

  watch: {
    holdList() {
      this.loadData();
    },

    holdListAnalytics() {
      this.parseData();
    },

    activeIndex() {
      setActivePieChartSlice(this.chart, this.activeIndex);
    }
  },

  methods: {
    ...mapActions({
      getAnalytics: 'HoldLists/getAnalytics'
    }),

    showDetails(params) {
      if (params.index === this.activeIndex) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = params.index;
      }

      this.$emit('slice-selected', this.selectedAnalytics)
    },

    parseData() {
      this.labels = [];
      this.values = [];
      this.dataLebels = [];

      this.holdListAnalytics.forEach(item => {
        const total = item.pros_count + item.cons_count;
        this.values.push(total);
        this.labels.push(item.advert.name);
        this.dataLebels.push(total ? `+${item.pros_count}\n-${item.cons_count}` : '');
      });
    },

    drawChart() {
      setTimeout(() => {
        if (this.values.length === 0) return;

        this.chart && this.chart.destroy();

        const canvas = this.$refs.piechart;

        const data = {
          labels: this.labels,
          values: this.values,
          dataLebels: this.dataLebels,
          // activeIndex: this.activeIndex,
        };

        const options = {
          onClick: this.showDetails,
        };

        this.chart = createPieChart(canvas, data, options);
      }, 0);
    },

    loadData() {
      if (!this.holdList) return;

      this.getAnalytics({hold_list_id: this.holdList.id})
      .then(response => this.drawChart())
      .catch(error => console.log(error));
    }
  },

  mounted() {
    this.drawChart();
  },

  created() {
    this.loadData();
  }
}
</script>

<style>

</style>