<template>
    <router-link :to="{ name: 'view_holdlist', params: { hold_list_id: holdlist.id, type: this.type } }">
        <div  class="channel" :class="variants[index % 3]">
            <div class="c-summary">
                <div class="cs-name">
                    <h5 class="name">{{ intent.name }}</h5>
                    <p class="desc font-small text-muted text-truncate">
                        {{ adNames }}
                    </p>
                </div>

                <div class="last-activity">
                    <span class="time font-xs ">
                        {{ intent.status.name }}
                    </span>
                    <div class="icon">
                    <i class="mdi mdi-check"></i>
                    </div>
                </div>
            </div>

            <div class="ad-items">
                <img class="ad-item"
                    v-for="(item, index) in items"
                    :key="index"
                    :src="item.advert.cover_media.file_url"
                />
            </div>
        </div>
    </router-link>
</template>

<script>
import { get } from 'lodash';

export default {
    props: ['index', 'holdlist', 'type'],

    data() {
        return {
            variants: ['var-1', 'var-2', 'var-3'],
        }
    },

    computed: {
        items() {
            return get(this.holdlist, 'items', [])
        },

        adNames() {
            return this.items.map(item => item.advert.name).join(', ');
        },

        intent() {
            return get(this.holdlist, 'intent', {})
        }
    },

    mounted() {
        // console.log({ items: this.items, adNames: this.adNames, intent: this.intent})
    }
}
</script>

<style>

</style>