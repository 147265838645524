<template>
    <div class="font-big d-flex justify-content-between font-weight-bolder rounded align-items-center">
        <div class="text-tertiary">
            {{ title }} <br/>
            <span class="text-muted font-small font-weight-lighter">
                Facebook, Google+
            </span>
        </div>

        <div class="">
            <!-- <button type="button" class="btn elevation-2 instagram btn-sm" @click.prevent="submit('instagram')">
                <i class="mdi mdi-instagram"></i>
            </button> -->
            <button type="button" class="btn elevation-2 facebook btn-sm" @click.prevent="submit('facebook')">
                <i class="mdi mdi-facebook"></i>
            </button>
            <button type="button" class="btn elevation-2 google btn-sm" @click.prevent="submit('google')">
                <i class="mdi mdi-google"></i>
            </button>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: ['title'],

    methods: {
        ...mapActions({
            socialLoginGetRedirectUrl: 'Authentications/socialLoginGetRedirectUrl'
        }),

        submit(provider) {
            this.showLoader(`Redirecting to ${provider}... Please wait`);

            this.socialLoginGetRedirectUrl(provider)
                .then(response => {
                    this.hideLoader();

                    // return console.log(response)

                    if (response.url) {
                        window.location.href = response.url;
                    } else {
                        this.showError()
                    }
                })
                .catch(error => {
                    this.hideLoader();
                    this.showError();
                })
        },

        showError() {
            this.notifyError(`Something unexpected happened. Please use username/password method or try again later`);
        }
    }
}
</script>