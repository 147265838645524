import Login from '@/views/common/authentication/Login.vue';
import VerifyEmail from '@/views/common/verifications/Email.vue';
import Register from '@/views/common/authentication/Register.vue';
import ResendEmail from '@/views/common/verifications/ResendEmail.vue';
import ResetPassword from '@/views/common/authentication/ResetPassword.vue';
import ForgotPassword from '@/views/common/authentication/ForgotPassword.vue';
import SocialLogin from '@/views/common/authentication/SocialLogin.vue';
import FinishSetup from '@/views/common/authentication/FinishSetup.vue';

import GettingStarted from '@/views/customer/getting-started/Index.vue';
import ProductListing from '@/views/customer/getting-started/ProductListing.vue';
import CreateChannel from '@/views/customer/getting-started/CreateChannel.vue';
import GSProductDetails from '@/views/customer/getting-started/ProductDetail.vue';

import GuestHoldlist from '@/views/customer/holdlists/Guest.vue';

import routes from './modules';

let links = [
    {
        path: '/',
        name: 'welcome',
        redirect: '/login'
    },

    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },

    {
        path: '/register',
        name: 'register',
        redirect: '/getting-started'
    },

    {
        path: '/getting-started',
        name: 'geting_started',
        component: GettingStarted
    },

    {
        path: '/getting-started/products/:mood/:ids',
        name: 'product_liting',
        component: ProductListing
    },

    {
        path: '/getting-started/products/:id/:mood/:ids',
        name: 'gs_product_detail',
        component: GSProductDetails
    },

    {
        path: '/getting-started/new-channel/:mood/:ids',
        name: 'create_channel',
        component: CreateChannel
    },

    {
        path: '/shlguest/:public_id',
        name: 'guest_holdlist',
        props: true,
        component: GuestHoldlist
    },

    {
        path: '/passwords/forgot',
        name: 'forgot_password',
        component: ForgotPassword,
        meta: {
            title: 'Forgot Password'
        }
    },

    {
        path: '/passwords/reset/:token',
        name: 'reset_password',
        props: true,
        component: ResetPassword,
        meta: {
            title: 'Reset Password'
        }
    },

    {
        path: `/verifications/email/:token?`,
        name: 'verify_email',
        props: true,
        component: VerifyEmail,
        meta: {
            title: `Verify Email Address`
        }
    },

    {
        path: `/verifications/resend_email`,
        name: 'resend_email',
        props: true,
        component: ResendEmail,
        meta: {
            title: `Verify Email Address`
        }
    },

    {
        name: 'social_login',
        path: '/authorize/:provider/callback',
        component: SocialLogin,
        props: true,
        meta: {
            title: `Login With Social Media`
        }
    },

    {
        name: 'finish_setup',
        path: '/complete-setup',
        component: FinishSetup,
        meta: {
            title: 'Complete Your Account Setup'
        }
    },

    ...routes
]

export default links;