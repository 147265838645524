<template>
    <div
        class="connection elevation d-flex flex-column align-items-center justify-content-center"
        :class="{ active: active }"
        @click="$emit('click')">
        <i class="mdi mdi-check-circle font-big text-success icon" v-if="shared"></i>
        <img
            :src="conn.friend ? conn.friend.image_url : '/img/empty.jpg'"
            class="avatar-xs circle"/>

        <h5 class="m-0 text-truncate text-primary font-small name text-center" style="width: 100%;">
            {{ conn.friend ? `@${conn.friend.username}` : conn.friend_id }}
        </h5>
        <p class="m-0 text-muted text-truncate font-xs status">
            {{ conn.friend ? 'Joined' : 'Guest' }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        conn: {
            type: Object,
            required: true
        },
        active: {
            type: Boolean,
            default: false
        },
        shared: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>

</style>