<template>
  <div class="popup-dialog" @click="$emit('cancel')">
    <div class="content" :class="{'no-space-top': noSpaceTop }" @click.stop="">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupDialog',
  props: {
    noSpaceTop: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>