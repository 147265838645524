<template>
  <div class="answers">
    <div class="responsive-page-width" :class="`align-items-${alignItems}`">
        <transition name="router">
            <div class="answer-options" v-if="showOptions">
                <slot/>
            </div>
        </transition>
    </div>
</div>
</template>

<script>
export default {
    props: {
        showOptions: {
            type: Boolean,
            default: false
        },
        alignItems: {
            type: String,
            default: 'center'
        }
    }
}
</script>

<style>

</style>