<template>
    <div class="">
        <div class="heading">
            <h3 class="title">Which <span>Color(s)</span>?</h3>
            <p class="mb-3 description text-muted text-center">
                Select color for each product
            </p>
        </div>

        <div class="p-0 mb-3 color-set" v-for="(product, index) in productTypes" :key="index">
            <div class="label m-0">{{ product.name }}</div>
            <div class="colors">
                <div
                    v-for="(color, ind) in allColors"
                    @click="setValue(product.id, [
                        ...(value[product.id] || []),
                        color
                    ])"
                    :class="{ 'active': (value[product.id] || []).includes(color) }"
                    class="color elevation-2"
                    :data-title="color"
                    :key="ind"
                    :style="{ background: color }">
                    <i class="mdi mdi-check font-big"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        productTypes: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        ...mapGetters({
            allColors: 'Intents/getColors'
        })
    },

    methods: {
        setValue(id, values) {
            this.$emit('input', {
                ...this.value,
                [id]: values
            })
        },

        init() {
            for (let productType of this.productTypes) {
                this.setValue(productType.id, this.value[productType.id] || []);
            }
        },
    },

    created() {
        this.init();
    },
}
</script>

<style>

</style>