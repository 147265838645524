import ViewIntent from '@/views/customer/intents/view/Index.vue';
import CreateIntent from '@/views/customer/intents/create/Index.vue';
import Dashboard from '@/views/customer/dashboard/Index.vue';
import Interests from '@/views/customer/interests/Index.vue'
import SelectInterests from '@/views/customer/interests/SelectInterests.vue'
import SecureAccount from '@/views/customer/account/SecureAccount.vue'
import ProductDetails from '@/views/customer/intents/ProductDetail.vue';
import Messaging from '@/views/customer/messaging/Index.vue';
import Channels from '@/views/customer/channels/Index.vue';

import HoldList from '@/views/customer/holdlists/Index.vue';
import FriendsHoldList from '@/views/customer/holdlists/Friends.vue';
import ViewHoldlist from '@/views/customer/holdlists/View.vue';

let routes = [
    {
        name: 'list_channels',
        path: 'channels',
        component: Channels,
        meta: {
            title: 'View Channels'
        }
    },

    {
        name: 'my_hold_lists',
        path: 'holdlists/self',
        component: HoldList,
        meta: {
            title: 'My Hold List'
        }
    },

    {
        name: 'friends_hold_list',
        path: 'holdlists/friends',
        component: FriendsHoldList,
        meta: {
            title: 'My Friends Hold List'
        }
    },

    {
        name: 'view_holdlist',
        path: 'holdlists/:type/:hold_list_id',
        component: ViewHoldlist,
        props: true,
        meta: {
            title: 'View Hold List Item'
        }
    },

    {
        name: 'create_intent',
        path: 'intents/create',
        component: CreateIntent,
        meta: {
            title: 'Create New Channel'
        }
    },

    {
        name: 'view_intent',
        path: 'intents/view/:intent_id',
        component: ViewIntent,
        meta: {
            title: 'View Intent'
        },
        props: true
    },

    {
        name: 'dashboard',
        path: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard'
        }
    },

    {
        name: 'select_interests',
        path: 'interests/setup',
        component: SelectInterests,
        meta: {
            title: 'Select You Interests'
        }
    },

    {
        name: 'interests',
        path: 'interests',
        component: Interests,
        meta: {
            title: 'Interests'
        }
    },

    {
        name: 'secure_account',
        path: 'account/secure',
        component: SecureAccount,
        props: true,
        meta: {
            title: 'Secure your Account'
        }
    },

    {
        path: '/messaging',
        name: 'messaging',
        component: Messaging,
        meta: {
            title: 'Messaging'
        }
    },

    {
        path: '/products/:id',
        name: 'product_detail',
        component: ProductDetails
    },
];

export default routes;