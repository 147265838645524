<template>
  <div class="comments">
    <h5 class="title">Latest Comments</h5>
    <p class="desc font-xs text-muted text-truncate">
        See what your friends are saying about your channels.
    </p>

    <div class="comments-list">
        <div class="comment d-flex align-items-center p-2 mb-1" v-for="(comment, index) in commentsData" :key="index">
            <img
                class="avatar-xs circle"
                :src="comment.hold_list_item.advert.cover_media.file_url"/>
            <div class="content flex-grow-1 ml-2">
                <h5 class="m-0 font-small text-black">{{ comment.hold_list_item.advert.name }}</h5>
                <div class="text text-truncate font-xs text-black-fade">
                    {{ comment.text }}
                </div>
            </div>
            <div class="user d-flex flex-column align-items-center ml-1">
                <img
                    class="img avatar-xxs circle ml-1"
                    :src="comment.user.image_url"/>
                <span class="name font-xs text-black-fade text-truncate">
                    @{{ comment.user.username }}
                </span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
    props: {
        comments: {
            type: Object,
        }
    },

    computed: {
        commentsData() {
            return get(this.comments, 'data', [])
        }
    },

    watch: {
        comments() {
            // console.log(this.commentsData)
        }
    }
}
</script>

<style>

</style>