<template>
    <div class="master-layout font-small">
        <p-header/>

        <transition name="router">
            <router-view/>
        </transition>

        <verify-code
            :show="showVerify2FA"
            :isSetup="false"
            @success="goIntended"
            @cancel="showVerify2FA = false"/>

        <bottom-nav v-if="showBottomNav"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VerifyCode from '@/views/common/authentication/partials/Verify2FA.vue';

export default {
    components: {
        VerifyCode,
    },

    data() {
        return {
            showVerify2FA: false
        }
    },

    computed: {
        ...mapGetters({
            sidebarOpened: 'getSidebarOpened'
        }),

        showBottomNav() {
            return ![
                'secure_account',
                'view_holdlist',
            ].includes(this.$route.name);
        }
    },

    methods: {
        ...mapActions({
            check: 'Authentications/check2FA',
        }),

        check2FA() {
            this.check()
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                    this.showVerify2FA = true;
                })
        },

        goIntended() {
            this.showVerify2FA = false;
        },
    },

    created() {
        this.check2FA();
    }
}
</script>