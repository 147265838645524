<template>
  <div class="rank-icon" :class="rank">
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.0" viewBox="0 0 32 40">
    <path d="M31.994 26H26v6.002l-6.469-6.469a13.995 13.995 0 0 0 7.311-4.686L31.994 26zM5.152 20.848 0 26h6v5.998l6.464-6.465a14.018 14.018 0 0 1-7.312-4.685zm22.846-8.849c0 6.629-5.374 11.999-11.999 11.999C9.371 23.998 4 18.628 4 11.999 4 5.375 9.371 0 15.999 0c6.625 0 11.999 5.375 11.999 11.999zm-8.976 1.895L24.014 10h-5.995l-2.012-6-2.004 6H8.007l4.992 3.944-1.992 6.059 5.007-3.734 4.992 3.734-1.984-6.109z"/>
  </svg>
  </div>
</template>
<script>
export default {
  props: ['rank', 'level']
}
</script>