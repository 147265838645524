import Service from '@/services/hold_list_comments.js';

const state = {
    all: [],
    pageDetails: {
        to: 0,
        from: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        currentPage: 0,
    },
};

const getters = {
    getAll(state) {
        return state.all;
    },

    getPageDetails(state) {
        return state.pageDetails;
    },
};

const mutations = {
    SET(state, { clearState, payload }) {
        state.pageDetails = {
            total: payload.total || 0,
            from: payload.from || 0,
            to: payload.to || 0,
            currentPage: payload.current_page || 0,
            lastPage: payload.last_page || 0,
            perPage: payload.per_page || 0
        }

        let all = payload.data ? payload.data : payload;

        if (clearState) {
            state.all = all;
        } else {
            state.all = [...state.all, ...all];
        }
    },

    ADD_COMMENT(state, comment) {
        state.all.push(comment);
    }
};

const actions = {
    index({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET', {
                    clearState: params?.clearState,
                    payload: response
                });
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    store({ commit }, params) {
        return Service.store(params)
            .then(response => {
                commit('ADD_COMMENT', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    clearState({ commit }) {
        commit('SET', { clearState: true, payload: [] });
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}