<template>
    <div class="holdlist-share">
        <div class="tabs mb-4">
            <button
                class="btn font-small text-truncate"
                @click="activeTab = 'conn'"
                :class="{active: activeTab === 'conn'}">
                <i class="mdi mdi-account-group"></i>
                <span class="font-xs">Connections</span>
            </button>

            <button
                class="btn font-small ml-2"
                @click="activeTab = 'new'"
                :class="{ active: activeTab === 'new'}">
                <i class="mdi mdi-shape-polygon-plus"></i>
                <span class="font-xs">New Connections</span>
            </button>

            <button
                class="btn font-small ml-2 copy"
                @click="copyLink">
                <i class="mdi mdi-content-copy"></i>
                <span class="font-xs">Copy Link</span>
            </button>
        </div>

        <connections
            @share="share"
            v-if="activeTab === 'conn'"
            :holdList="holdList"
            :guest="guest"/>

        <new-connections
            @share="share"
            v-if="activeTab === 'new'"/>
    </div>
</template>

<script>
import NewConnections from '@/views/customer/holdlists/share/NewConnections.vue';
import Connections from '@/views/customer/holdlists/share/Connections.vue';
import { mapActions } from 'vuex';

export default {
    props: {
        holdList: {
            type: Object,
            required: true
        },
        guest: {
            type: String
        }
    },
    components: {
        Connections,
        NewConnections,
    },
    data() {
        return {
            activeTab: 'conn'
        }
    },
    methods: {
        ...mapActions({
            shareHoldList: 'HoldLists/shareHoldList'
        }),

        copyLink() {
            const link = `${window.location.origin}/shlguest/${this.holdList.public_id}`;
            navigator.clipboard.writeText(link);
            console.log(link);
        },

        share(data) {
            data = {
                ...data,
                hold_list_id: this.holdList.id
            }

            this.showLoader(`Sharing holdlist... Please wait`);

            this.shareHoldList(data)
            .then(response => {
                this.hideLoader();
                this.$emit('shared');
            })
            .catch(error => {
                this.hideLoader();
            });
        }
    }
}
</script>

<style></style>