<template>
<div class="intro">
    <div class="content responsive-page-width">
        <img class="svgbg mb-4" src="/img/svgbg.svg"/>

        <h2 ref="title" class="title-text mt-5"></h2>

        <transition name="router">
            <lets-go @click="$emit('next')" v-if="showLetsGo"/>
        </transition>
    </div>
</div>
</template>

<script>
import TypeIt from "typeit";
import LetsGo from './LetsGo.vue';

export default {
  components: { LetsGo },
    data() {
        return {
            showLetsGo: false,
        }
    },

    methods: {
        typeHeading() {
            new TypeIt(this.$refs.title, {
                speed: 20,
                startDelay: 100,
                cursor: false,
                afterComplete: async (instance) => {
                    this.showLetsGo = true;
                },
            })
                .empty()
                .type(`Manage all your customer journeys from a single place`)
                .type(`<p class="mt-4 style-script font-big">Convenience  .  Privacy</p>`)
                .go();
        },
    },

    mounted() {
        setTimeout(() => {
            this.typeHeading();
        }, 0);
    }
}
</script>

<style>

</style>