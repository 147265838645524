<template>
    <div class="pb-5">
        <div class="heading">
            <h3 class="title" v-html="heading.title"></h3>
            <p class="mb-3 description text-muted text-center">
                {{ heading.description }}
            </p>
        </div>

        <transition-group name="swipe" class="row no-gutters">
            <div
                class="col-lg-3 col-md-3 col-4 p-1"
                v-for="(type, index) in preferences"
                :key="`${index}-interest`">
                <button
                    type="button"
                    @click.prevent="addPreference(type)"
                    class="preference-item elevation rounded btn-block"
                    :class="{ 'selected': value.includes(type.id) }">
                    <i class="mdi mdi-checkbox-marked-circle font-xl check"></i>
                    <i class="mdi mdi-checkbox-blank-circle-outline font-xl uncheck"></i>
                    <img :src="type.image_url" class="avatar-xs"/>
                    <p class="m-0 mt-2 text-capitalize font-xs">{{ type.name }}</p>
                </button>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        type: {
            type: String,
            default: 'product',
        },

        value: {
            type: Array,
            default: () => ([]),
        },

        productTypes: {
            type: Array,
            default: () => [],
        },

        heading: {
            type: Object,
            default: () => ({
                title: `Product <span>Preferences(s)</span>?`,
                description: 'Select preferences for all products',
            }),
        }
    },

    data() {
        return {
            preferences: [],
        };
    },

    methods: {
        ...mapActions({
            index: 'Intents/getPreferences'
        }),

        addPreference(type) {
            if (this.value.includes(type.id)) {
                this.$emit('input', this.value.filter(id => id != type.id));
                return;
            }
            
            this.$emit('input', [...this.value, type.id]);
        },

        loadPreferences() {
            this.index({ type: this.type })
                .then(response => {
                    this.preferences = response;
                })
                .catch(error => {
                    this.hideLoader();
                    console.log(error);
                })
        },
    },

    created() {
        this.loadPreferences();
    }
}
</script>

<style>

</style>