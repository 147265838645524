<template>
    <responsive-width>
        <template v-if="ad">
            <product-detail :ad="ad" />
        </template>
    </responsive-width>
</template>

<script>
import ProductDetail from '@/views/customer/products/partials/Details.vue';
import { mapActions } from 'vuex';

export default {
    components: {
        ProductDetail
    },

    data() {
        return {
            ad: null
        }
    },

    computed: {
        mood() {
            return this.$route.params.mood;
        },

        productTypeIds() {
            return this.$route.params.ids;
        }
    },

    methods: {
        ...mapActions({
            showAdvert: 'Adverts/showGettingStartedAd'
        })
    },

    created() {
        this.showAdvert(this.$route.params.id)
            .then(response => {
                this.ad = response;
                console.log(response);
            })
            .catch(error => null);
    }
}
</script>

<style></style>