<template>
  <div class="question">
        <div class="responsive-page-width">
            <heading />

            <div class="question-content">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
import Heading from '@/views/customer/getting-started/steps/partials/Heading.vue';

export default {
    components: {
        Heading,
    },
}
</script>

<style>

</style>