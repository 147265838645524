import Empty from './shared/Empty.vue';
import AppCard from './shared/AppCard.vue';
import Modal from './shared/BaseModal.vue';
import BaseInput from './forms/BaseInput.vue';
import BaseTextArea from './forms/BaseTextArea.vue';
import BaseSelect from './forms/BaseSelect.vue';
import DatePicker from './forms/DateSingle.vue';
import Pagination from './shared/Pagination.vue';
import BaseCheckbox from './forms/BaseCheckbox.vue';
import TableSearchInput from './forms/TableSearchInput.vue';
import BaseSearchBox from './forms/BaseSearchBox.vue';
import BaseInputGroup from './forms/BaseInputGroup.vue';
import BaseRadioGroup from './forms/BaseRadioGroup.vue';
import FormGroupAppend from './forms/FormGroupAppend.vue';
import BaseCheckboxGroup from './forms/BaseCheckboxGroup.vue';
import TimeCounter from './shared/TimeCounter.vue';
import BaseSelectWrapper from './forms/BaseSelectWrapper.vue';
import LayoutCard from './../views/customer/profile/partials/Layout.vue';
import Logo from './shared/Logo.vue';
import LogoText from './shared/LogoText.vue';
import GoogleLoader from './shared/GoogleLoader.vue';
import DateRangePicker from './forms/DateRange.vue';
import CustomModal from './shared/CustomModal.vue';
import BaseTokenInput from './forms/BaseTokenInput.vue'
import ReadMoreText from './shared/ReadMoreText.vue'
import ResponsiveWidth from './shared/ResponsiveWidth.vue'
import PHeader from './shared/PHeader.vue'
import BottomNav from './shared/BottomNav.vue'
import BtnWithBorderGradient from './shared/BtnWithBorderGradient.vue'

export default {
    'logo': Logo,
    'empty': Empty,
    'modal': Modal,
    'read-more-text': ReadMoreText,
    'app-card': AppCard,
    'date-range-picker': DateRangePicker,
    'base-input': BaseInput,
    'base-textarea': BaseTextArea,
    'pagination': Pagination,
    'base-select': BaseSelect,
    'time-counter': TimeCounter,
    'base-checkbox': BaseCheckbox,
    'base-date-picker': DatePicker,
    'base-search-box': BaseSearchBox,

    'responsive-width': ResponsiveWidth,

    'base-input-group': BaseInputGroup,
    'base-radio-group': BaseRadioGroup,
    'form-group-append': FormGroupAppend,
    'base-checkbox-group': BaseCheckboxGroup,

    'base-select-wrapper': BaseSelectWrapper,

    'layout-card': LayoutCard,

    'table-search-input': TableSearchInput,

    'google-loader': GoogleLoader,

    'custom-modal': CustomModal,
    'logo-text': LogoText,

    'base-token-input': BaseTokenInput,

    'p-header': PHeader,
    'bottom-nav': BottomNav,
    'btn-with-border-gradient': BtnWithBorderGradient
}