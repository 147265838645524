<template>
    <div>
        <product-details-popup />
    </div>
</template>

<script>
import ProductDetailsPopup from '@/views/customer/products/DetailsPopup.vue';

export default {
    components: {
        ProductDetailsPopup
    }
}
</script>

<style>

</style>