<template>
<div>
    <page-title title="Support Tickets">
        <button class="btn btn-secondary font-small elevation-2 pt-2 pb-2 ml-2" @click.prevent="showAddTicket = true">
            <i class="mdi mdi-plus-circle"></i> New Ticket
        </button>
    </page-title>

    <div class="row no-gutters mb-3">
        <div class="col-md-4 col-12 p-1">
            <div class="">
                <button
                    @click.prevent="params.status = 'all'"
                    :class="{'active': params.status == 'all'}"
                    class="status-btn mb-1 font-xs secondary"
                    type="button">
                    <i class="mdi mdi-format-list-bulleted"></i> All
                </button>

                <button
                    @click.prevent="params.status = 'open'"
                    :class="{'active': params.status == 'open'}"
                    class="status-btn mb-1 font-xs success"
                    type="button">
                    <i class="mdi mdi-checkbox-marked-circle"></i> Opened
                </button>

                <button
                    @click.prevent="params.status = 'closed'"
                    class="status-btn mb-1 font-xs warning"
                    :class="{'active': params.status == 'closed'}"
                    type="button">
                    <i class="mdi mdi-sync"></i> Closed
                </button>
            </div>
        </div>

        <div class="col-md-4 col-12 p-1">
            <div class="row no-gutters">
                <div class="col-6">
                    <div class="form-group type-3 filter elevation m-0">
                        <label class="font-xs m-0">From Date</label>
                        <datetime
                            input-class="form-control font-small no-radius text-input"
                            placeholder="Start date"
                            v-model="params.start_date"
                            title="From"></datetime>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group type-3 filter elevation m-0">
                        <label class="font-xs m-0">To Date</label>
                        <datetime
                            input-class="form-control font-small no-radius text-input"
                            placeholder="End date"
                            v-model="params.end_date"
                            title="To"></datetime>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4 p-1">
            <table-search-input
                class="flex-grow-1"
                v-model="keyword"
                @search="loadData"
                placeholder="Search by keywords...">
            </table-search-input>
        </div>
    </div>

    <app-card>
        <empty title="No team member found" v-if="!all.length"/>

        <div class="table-responsive" v-else>
            <table class="table font-small m-0">
                <tbody>
                    <tr v-for="(ticket, index) in all" :key="index">
                        <td class="bg-default-dark" style="border-bottom:10px solid white;">
                            <span class="font-weight-bold d-block">#{{ ticket.ticket_number }}</span>

                            <p class="font-xs text-muted mt-1 mb-1">{{ formatAge(ticket.created_at) }}</p>

                            <span v-if="ticket.status" class="badge p-2 d-block" :class="`status-${ticket.status.name.toLowerCase()}`">
                                {{ ticket.status.name }}
                            </span>

                            <button type="button" @click.prevent="viewTicket(ticket.id)" class="btn btn-outline-secondary font-xs btn-sm btn-block mt-3">
                               <i class="mdi  mdi-message-text-outline"></i> Details
                            </button>
                        </td>
                        <td class="bg-default" style="border-bottom:10px solid white;">
                            <p class="font-weight-bold" >
                                <span v-if="ticket.topic && ticket.topic.name == 'Other'">{{ ticket.other_topic }}</span>
                                <span v-else>{{ ticket.topic && ticket.topic.name }}</span>
                            </p>

                            <p class="text-muted text-justify">
                                {{ ticket.text }}
                            </p>
                            <!-- <hr/> -->
                            <div class="font-xs m-0">
                                Created by <span class="font-weight-bold">{{ ticket.created_by && ticket.created_by.fullname }}</span> on
                                <span>{{ ticket.created_at | formattedDateTime }}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </app-card>

    <scroll-loader :loader-method="loadNextPage" :loader-disable="!showLoadMore"/>

    <add-ticket
        :show="showAddTicket"
        @done="loadData"
        @cancel="addTicket(null)"/>

    <view-ticket
        :ticket_id="ticket_id"
        @update="updateTicket"
        @cancel="viewTicket(null)"/>

    <update-ticket
        :ticket_id="ticket_id"
        :name="ticket_name"
        :show="showUpdateTicket"
        @cancel="updateTicket(null)"/>

</div>
</template>

<script>
import AddTicket from './partials/AddTicket';
import { mapActions, mapGetters } from 'vuex';
import ViewTicket from './ViewTicket.vue';
import UpdateTicket from './partials/UpdateTicket'

export default {
    components: {
        AddTicket,
        ViewTicket,
        UpdateTicket
    },

    data() {
        return {
            ticket_id: null,
            showAddTicket: false,
            showUpdateTicket: false,
            ticket_name: '',

            keyword: '',

            params: {
                start_date: '',
                end_date: '',
                status: 'all'
            }
        }
    },

    computed: {
        ...mapGetters({
            all: 'Tickets/getAll',
            pageDetails: 'Tickets/getPageDetails',
            statuses: 'Tickets/getStatuses'
        })
    },

    watch: {
        params: {
            deep: true,
            immediate: true,
            handler() {
                this.loadData({clearState: true});
            }
        }
    },

    methods: {
        ...mapActions({
            index: 'Tickets/index',
            destroy: 'Tickets/destroy',
            getStatuses: 'Tickets/getStatuses'
        }),

        viewTicket(ticket_id) {
            this.ticket_id = ticket_id;
        },

        updateTicket(ticket_name) {
            this.ticket_name = ticket_name;
            this.showUpdateTicket = !!ticket_name;
        },

        addTicket(member) {
            this.showAddTicket = !!member;
            this.member = member;
        },

        async deleteMember(member) {
            let result = await this.swalConfirm(`Are you sure?`, `Please confirm you wish to delete this member from your team`, `Yes, Delete`);

            if (!result.value) {
                return;
            }

            this.showLoader();

            this.destroy(member.id)
                .then(response => {
                    this.hideLoader();
                    this.loadData();
                })
                .catch(error => {
                    this.hideLoader();
                })
        },

        loadNextPage() {
            if (this.showLoadMore) {
                this.loadData(this.loadMore);
            }
        },

        loadData(query) {
            let params = {
                ...query,
                ...this.params,
                paginate: true,
                per_page: 5,
                keyword: this.keyword
            }

            // this.showLoader('Getting support tickets... Please wait')

            this.index(params)
                .then(response => {
                    this.hideLoader()
                })
                .catch(error => {
                    this.hideLoader()
                })
        }
    },

    mounted() {
        let ticket_id = parseInt(this.$route.query.ticket_id);

        if (ticket_id) {
            this.viewTicket(ticket_id);
        }
    }
}
</script>