<template>
    <div class="">
        <div class="heading">
            <h3 class="title">Which <span>Size(s)</span>?</h3>
            <p class="mb-3 description text-muted text-center">
                Select size for each product
            </p>
        </div>

        <div class="p-0 mb-3 color-set" v-for="(product, index) in productTypes" :key="index">
            <div class="label m-0">{{ product.name }}</div>
            <div class="colors">
                <div v-for="(size, ind) in sizes" @click="add(
                    product.id, [...(value[product.id] || []), size]
                )"
                    :class="{ 'active': (value[product.id] || []).includes(size) }" class="color elevation-2"
                    :key="ind">
                    <div class="name">{{ size }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        productTypes: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        sizes() {
            return ['S', 'M', 'L', 'XL'];
        }
    },

    methods: {
        init() {
            const vals = {...this.value};

            for (let productType of this.productTypes) {
                vals[productType.id] = vals[productType.id] ? vals[productType.id] : [];
            }

            this.$emit('input', vals);
        },

        add(id, vals) {
            this.$emit('input', {...this.value, [id]: vals });
        }
    },

    created() {
        this.init();
    },
}
</script>

<style></style>