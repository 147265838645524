<template>
  <div class="holdlist-analytics">
    <template v-if="hasComments">
      <charts
        :holdList="holdList"
        @slice-selected="showSlice"
      />

      <div id="holdlist-analytics-content">
        <pros-cons v-model="type"/>
      </div>

      <div v-if="type">
        <word-cloud
          :holdList="holdList"
          :type="type"
          :holdListItem="holdListItem"/>

        <comments
          :holdList="holdList"
          :type="type"
          :holdListItem="holdListItem"/>
      </div>
    </template>
    <empty title="No comment has been added yet"  v-else/>
  </div>
</template>

<script>
import Charts from '@/views/customer/holdlists/analytics/Charts.vue';
import Comments from '@/views/customer/holdlists/analytics/Comments.vue';
import WordCloud from '@/views/customer/holdlists/analytics/WordCloud.vue';
import ProsCons from '../comments/ProsCons.vue';
import { TYPE_PROS, TYPE_CONS } from '@/constants/holdList.js';

export default {
  props: {
    holdList: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Charts,
    Comments,
    WordCloud,
    ProsCons,
  },

  data() {
    return {
      TYPE_PROS,
      TYPE_CONS,
      type: null,
      holdListItem: null,
    }
  },

  computed: {
    hasComments() {
      return this.holdList && this.holdList.comments_count > 0;
    }
  },

  watch: {
    type() {
      this.scrollToContent();
    }
  },

  methods: {
    scrollToContent() {
      setTimeout(() => {
        const el = document.getElementById('holdlist-analytics-content');
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    },

    showSlice(holdListItem) {
      if (holdListItem) {
        this.type = this.TYPE_PROS;
        this.holdListItem = holdListItem;
      } else {
        this.type = null;
        this.holdListItem = null;
      }
    }
  }
}
</script>

<style>

</style>