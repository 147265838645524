<template>
    <responsive-width>
        <h3 class="text-center mb-4 mt-3" id="interests"></h3>

        <div class="row justify-content-end mb-4">
            <div class="col-md-5">
                <base-search-box
                    :showLeftBtn="false"
                    class="sticky"
                    v-model="keyword"
                    placeholder="Filter interests by keyword">
                </base-search-box>
            </div>
        </div>

        <div class="interests pb-5">
            <div >
                <transition-group name="swipe" class="row no-gutters">
                    <div class="col-lg-2 col-md-2 col-4 p-1 full-height" v-for="(type, index) in filtered" :key="`${index}-interest`">
                        <button type="button" @click.prevent="addInterest(type)" class="interest rounded btn-block full-height" :class="{'selected': selected.includes(type.id)}">
                            <component class="icon elevation" :is="type.icon"/>
                            <p class="m-0 mt-2 text-capitalize">{{ type.name }}</p>
                            <i class="mdi mdi-checkbox-marked-circle check"></i>
                        </button>
                    </div>
                </transition-group>
            </div>


            <transition name="swipe">
                <button class="btn btn-success floating pl-3 pr-3 font-small" @click.prevent="submit" v-if="selected.length >= requiredLength">
                    <i class="mdi mdi-checkbox-marked-circle-outline"></i> <span class="font-14 ml-1">Save interests</span>
                </button>
            </transition>
        </div>
    </responsive-width>
</template>
<script>
import TypeIt from 'typeit';
import { mapActions } from 'vuex';
import Icons from '@/components/icons';

export default {
    components: {
        ...Icons
    },

    data() {
        return {
            types: [],
            filtered: [],
            selected: [],
            keyword: ''
        }
    },

    computed: {
        leftBtnIcon() {
            if (this.selected.length) {
                return `mdi mdi-select-off`
            } else {
                return `mdi mdi-select-all`
            }
        },

        requiredLength() {
            return 5;
        }
    },

    watch: {
        keyword() {
            this.performFiltering()
        }
    },

    methods: {
        ...mapActions({
            getTypes: 'Interests/getTypes',
            store: 'Interests/store'
        }),

        async unselect() {
            let title, description, button;

            if (this.selected.length) {
                button = `Unselect selected`
                title = `Unselect Interests?`;
                description = `This will unselect all the interests that you've previously selected`;
            } else {
                button = `Select All`
                title = `Select All Interests?`;
                description = `This will all interests that are displayed here`;
            }

            let result = await this.swalConfirm(title, description, button);

            if (!result.value) return;

            if (this.selected.length) {
                this.selected = [];
            } else {
                this.selected = this.filtered.map(type => type.id);
            }
        },

        submit() {
            if (!this.selected.length) {
                return this.swalError(`Attention!`, `Select at least 5 interests to proceed`);
            }

            this.showLoader(`Saving your interests... Please wait`);

            this.store({interest_type_ids: this.selected})
                .then(response => {
                    this.hideLoader();
                    // console.log(response);
                    this.$router.replace({name: `interests`})
                })
                .catch(error => {
                    this.hideLoader();
                    // console.log(error);
                })
        },

        typeHeading() {
            new TypeIt("#interests", {
                speed: 50,
                startDelay: 100
            })
            .empty()
            .type(`Setup your interests...`, { delay: 500 })
            .delete()
            .type(`Select at least 5 interests`)
            .move(-10)
            .delete(1)
            .type(`<span class="badge badge-warning circle">5</span>`)
            .move(10)
            .type(`/hobbies.`)
            .go()
        },

        loadTypes() {
            this.showLoader('Getting interests... Please wait');

            this.getTypes()
                .then(response => {
                    this.hideLoader();
                    this.types = response;
                    this.typeHeading();
                    this.performFiltering();
                })
                .catch(error => {
                    this.hideLoader();
                    console.log(error);
                })
        },

        addInterest(type) {
            let index = this.selected.indexOf(type.id);

            if (index > -1) {
                this.selected.splice(index, 1);
            } else {
                this.selected.push(type.id)
            }
        },

        performFiltering() {
            if (!this.keyword) {
                this.filtered = [...this.types];
            } else {
                let parts = this.keyword.split(' ').filter(part => part.trim());

                this.filtered = this.types.filter(type => {
                    for (let part of parts) {
                        part = part.toLowerCase();

                        if (type.name.toLowerCase().includes(part)) {
                            return true;
                        }

                        if (type.description.trim() && type.description.toLowerCase().includes(part)) {
                            return true;
                        }
                    }

                    return false;
                })
            }
        }
    },

    mounted() {
        if (this.authUser.interests && this.authUser.interests.length) {
            this.selected = this.authUser.interests.map(interest => interest.interest_type_id);
        }

        this.loadTypes();
    }
}
</script>