<template>
  <responsive-width>
    <div class="messaging">
      <div class="search-form">
          <div class="form-group">
              <input type="text" class="form-control" placeholder="enter search term"/>
              <button type="submit" class="btn sf-submit">
                  <i class="mdi mdi-magnify"></i>
              </button>
          </div>
      </div>

      <div class="messages">
          <div class="message" v-for="i in 10" :key="i">
              <div class="m-logo _100">
                  <img src="/img/nike.png"/>
              </div>

              <div class="summary">
                  <h5 class="s-title">Try the new Ipod 18s</h5>
                  <p class="mb-0 text-muted s-desc">Lorem ipsum, dolor sit amet</p>
              </div>
              <div class="expiration">
                  <!-- if expires today, display time else show date -->
                  <span class="date text-muted">12/23</span>
                  <span class="count">4</span>
              </div>
          </div>
      </div>
    </div>
  </responsive-width>
</template>

<script>
import PHeader from '../../../components/shared/PHeader.vue'
export default {
  components: { PHeader },
  created() {
    this.setPheaderTitle('Messaging');
  }
}
</script>

<style>

</style>