<template>
    <div class="hold-list-item">
        <div class="profile p-1" v-if="advert">
            <div class="image d-flex justify-content-between align-items-center">
                <div class="logo mr-1">
                    <img :src="advert.merchant.logo_url" />
                </div>
                <i class="mdi mdi-checkbox-marked-circle verify-icon font-xxl" v-if="advert.merchant.is_verified"></i>
            </div>

            <div class="info d-none flex-grow-1 bg-white elevation-2">
                <div class="flex-grow-1">
                    <p class="font-xs m-0 text-trancate">
                        <a href="#" class="text-secondary">
                            {{ advert.merchant.name }}
                        </a>
                    </p>
                    <p class="font-xs text-muted mb-1">
                        {{ advert.published_at | fromNow }}
                        <span class="font-xs p-1 pl-2 pr-2 rounded float-right"
                            :class="{'bg-soft-success': visibility === 'private', 'bg-soft-danger': visibility === 'public'}"
                            v-if="visibility && !isFriends">
                            {{ visibility }}
                        </span>
                    </p>
                </div>
            </div>

            <div class="offer truncate mr-2 font-weight-bolder text-tertiary">
                {{ offer }}
            </div>
        </div>

        <google-loader v-else />
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import GoogleLoader from '@/components/shared/GoogleLoader.vue';

export default {
    props: ['advert', 'isPreview', 'visibility', 'isFriends', 'offer'],

    components: {
        GoogleLoader
    },

    computed: {
        brand() {
            if (!this.advert || !this.advert.values || !Array.isArray(this.advert.values)) {
                return [];
            }

            let brand = this.advert.values.find(item => item.name == 'brand');

            if (brand && brand.value) {
                return brand.value;
            }
        },
    },

    methods: {
        ...mapActions({
            holdOrUnhold: 'Statuses/holdOrUnhold'
        }),

        async unhold() {
            let result = await this.swalConfirm(`Remove from your hold list?`, `This will remove "${this.advert.name}" from your hold list. Are you sure?`, `Remove`);

            if (!result.value) return;

            this.holdOrUnhold({type: 'unhold', advert_id: this.advert.id})
                .then(response => null)
                .catch(error => null)
        },
    }
}
</script>