<template>
    <div class="products">
        <div class="search-form">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Search Product"/>
                <button type="submit" class="btn sf-submit">
                    <i class="mdi mdi-magnify"></i>
                </button>
            </div>
            <button type="button" class="btn sf-filter-btn">
                <i class="mdi mdi-filter"></i>
            </button>
            <button type="button" class="btn sf-filter-btn">
                <i class="mdi mdi-grid"></i>
            </button>
            <button type="button" class="btn sf-filter-btn" :class="{'active': status == 'viewed'}" @click="$emit('trashed')">
                <i class="mdi mdi-trash-can"></i>
            </button>
        </div>

        <div class="listings">
            <div class="row no-gutters" v-if="all.length">
                <div class="col-6 col-md-4 glide-items" :id="`col-${ad.id}`" v-for="ad in all" :key="ad.id">
                    <div data-glide-el="track" class="glide__track">
                        <div class="glide__slides">
                            <li class="glide__slide">
                                <product @viewAd="$emit('viewAd', ad.id)" :ad="ad"/>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
            <empty title="No ads found" v-else />
        </div>


        <scroll-loader
            :loader-method="loadNextPage"
            :loader-disable="!showLoadMore"
            v-if="pageDetails"/>

        <delete-update-popup />

        <report-advert
            :show="!!advertToReport"
            :advert="advertToReport"
            @cancel="advertToReport = null;"
            @advert-reported="id => excludedAds.push(id)"/>

        <snooze-settings
            :show="showSnooze"
            @cancel="showSnooze = false"/>

        <share-advert
            :show="!!advertToShare"
            :advert="advertToShare"
            @cancel="advertToShare = null"/>

        <answer-poll
            :show="!!advertPoll"
            :advert="advertPoll"
            @cancel="advertPoll = null"/>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import ViewStoreFront from '@/views/customer/interests/partials/StoreFront';
import StatusInterests from '@/views/customer/interests/partials/Interests';
import StatusUpdate from '@/views/customer/interests/partials/StatusUpdate';
import BottomTab from '@/components/navs/BottomTab';
import DeleteUpdatePopup from '@/views/customer/interests/partials/DeleteUpdatePopup.vue';
import ReportAdvert from '@/views/customer/interests/partials/ReportAdvert';
import ShareAdvert from '@/views/customer/interests/partials/ShareAdvert';
import AnswerPoll from '@/views/customer/interests/partials/AnswerPoll.vue';
import SnoozeSettings from '@/views/customer/interests/partials/SnoozeSettings.vue';
import Product from '@/views/customer/products/partials/product.vue';


export default {
    props: ['ads', 'pageDetails', 'status'],
    components: {
        Product,
        BottomTab,
        ViewStoreFront,
        StatusInterests,
        StatusUpdate,
        DeleteUpdatePopup,
        ReportAdvert,
        ShareAdvert,
        AnswerPoll,
        SnoozeSettings,
    },

    data() {
        return {
            showSnooze: false,

            keyword: '',
            interest_id: '',

            selectedUpdate: null,
            advertIndex: null,

            advertToReport: null,
            advertToShare: null,
            advertPoll: null,

            excludedAds: [],

            bottomNavItems: [
                {
                    id: 'new',
                    name: 'New Ads',
                    description: `Not yet viewed`
                },
                {
                    id: 'viewed',
                    name: 'Recently Viewed',
                    description: `Within 24 hours`
                },
            ]
        }
    },

    computed: {
        all() {
            return this.ads.filter(ad => !this.excludedAds.includes(ad.id));
        },
        availableStatuses() {
            return this.bottomNavItems.map(item => item.id);
        },
    },

    methods: {
        ...mapActions({
            index: 'Statuses/index',
            removeUpdate: 'Statuses/remove',
            storeImpressions: 'Campaigns/storeImpressions',
            getReportReasons: 'Statuses/getReportReasons',
        }),

        loadNextPage() {
            if (this.showLoadMore) {
                this.$emit('next-page', this.loadMore);
            }
        },

        saveImpressions(ids) {
            if (!this.isLoggedIn) return;

            this.storeImpressions({ ids })
                .then(response => null)
                .catch(error => console.log(error));
        },

        readImpressions: debounce(function () {
            if (!this.isLoggedIn) return;

            let ids = [];

            $('.status-update:not(.impression)').each((index, el) => {
                let id = $(el).attr('id');

                if (id && this.isInViewport($(el))) {
                    id = parseInt(id.replace('swipable_id_', ''));

                    if (id) {
                        ids.push(id);
                    }
                }
            });

            if (ids.length) {
                this.saveImpressions(ids);
            }
        }, 1000),

        isInViewport(el) {
            if (!this.isLoggedIn) return;

            if (typeof jQuery === "function" && el instanceof jQuery) {
                el = el[0];
            }

            var rect = el.getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
            );
        },
    },

    created() {
        if (this.isLoggedIn) {
            let status = this.$route.query.status;

            if (status && this.availableStatuses.includes(status)) {
                if (this.status != status) {
                    this.status = status;
                    return;
                }
            }

            this.getReportReasons().catch(error => null);
        }
    }
}
</script>

<style>
.glide__slide {
    /* background: blue; */
}
</style>