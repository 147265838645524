<template>
    <div class="details-popup-overlay" v-if="show">
        <div class="close-bar">
            <button type="button" class="btn circle avatar-xs font-big elevation-2" @click="closeDetails">
                <i class="mdi mdi-chevron-left font-xl"></i>
            </button>
        </div>

        <ad-details :ad="ad" v-if="ad"/>
    </div>
</template>

<script>
import AdDetails from './partials/Details.vue';
import bus from '@/event-bus';
import { mapActions } from 'vuex';
import { first } from 'lodash';

export default {
    components: { AdDetails },

    data() {
        return {
            ad: null,
            show: false,
            backgrounds: []
        }
    },

    methods: {
        ...mapActions({
            showAdvert: 'Adverts/showGettingStartedAd'
        }),

        loadAdvert(advert_id) {
            this.showAdvert(advert_id)
                .then(response => {
                    this.ad = response;
                    this.show = true;
                    this.applyBackground();
                })
                .catch(error => null);
        },

        closeDetails() {
            this.show = false;
            this.ad = null;
        },

        applyBackground() {
            const medias = this.ad.advert_media;
            if (!medias.length) return;

            const imgItem = first(medias);

            //load image url
            const img = new Image();
            img.crossOrigin = "Anonymous"
            img.src = imgItem.file_url;

            img.onload = () => {
                const colorThief = new ColorThief();
                const color = colorThief.getColor(img);
                const page = document.querySelector('.details-popup-overlay .product-detail');

                if (page) {
                    page.style.background = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
                }
            }
        }
    },

    created() {
        bus.$on('show-product-details-popup', (advert_id) => {
            this.loadAdvert(advert_id);
        });

        bus.$on('close-product-details-popup', () => {
            this.closeDetails();
        });

        // this.loadAdvert(13);
    },

    destroyed() {
        bus.$off('show-product-details-popup');
        bus.$off('close-product-details-popup');
    }
}
</script>

<style>

</style>