<template>
    <hold-lists type="friends"/>
</template>

<script>
import HoldLists from './Index.vue';

export default {
    components: { HoldLists }
}
</script>

<style>

</style>