<template>
    <div class="my-holdlists mb-4">
        <h5 class="title">{{ title }}</h5>
        <p class="desc font-xs text-muted text-truncate">{{ subtitle }}</p>
        <div class="channel-list">
            <div class="row no-gutters">
                <div class="col-2">
                    <router-link :to="{ name: type === 'self' ? 'my_hold_lists' : 'friends_hold_list' }">
                        <div class="channel-list-item view-action">
                            <i class="mdi mdi-menu"></i>
                        </div>
                    </router-link>
                </div>

                <div class="col-10" v-if="!items.length">
                    <div class="channel-list-item font-small text-muted">
                        No hold list found
                    </div>
                </div>

                <div :class="items.length === 2 ? 'col-4' : 'col-6'"
                        v-for="(item, index) in items"
                        :key="index"
                        @click="viewHoldList(item.hold_list_id)">
                    <div class="channel-list-item " :class="{ 'active-holdlist': index === 0 }" >
                        <div class="icon">
                            <i class="mdi mdi-pin"></i>
                        </div>

                        <div class="count font-xs">{{ item.count }}</div>

                        <p class="m-0 text-truncate font-xs name">{{ item.intent_name }}</p>
                    </div>
                </div>

                <div class="col-2" v-if="more > 2">
                    <router-link :to="{ name: 'list_channels' }">
                        <div class="channel-list-item view-more font-small">
                            {{ more - 1 }}+
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        holdLists: {
            required: true
        },
        type: {
            type: String,
            default: 'self'
        }
    },

    computed: {
        title() {
            return this.type === 'self' ? 'My Holdlists' : 'Friends Holdlists';
        },

        subtitle() {
            return this.type === 'self' ? 'Your current holdlists' : 'Your friends need your help';
        },

        items() {
            return this.holdLists && this.holdLists.data ? this.holdLists.data : [];
        },

        more() {
            return this.holdLists && this.holdLists.more;
        }
    },

    methods: {
        viewHoldList(hold_list_id) {
            this.$router.push({ name: 'view_holdlist', params: { hold_list_id, type: this.type } });
        }
    },
}
</script>

<style>

</style>