<template>
  <div class="pb-5">
    <div class="heading">
        <h3 class="title">Fina<span>lize</span></h3>
        <p class="mb-3 description text-muted text-center">
            Select return policy and delivery window if applicable
        </p>
    </div>
        <h4 class="mb-3 font-big">Return Policy</h4>
        <div class="mb-4 d-flex align-items-center text-muted font-small">
            <input
                id="return-policy"
                type="checkbox"
                class="mr-2"
                v-model="value.return_policy"/>
            <label for="return-policy" class="m-0">Only returnable items</label>
        </div>

        <h4 class="mb-3 mt-5 font-big">Delivery Window requirements</h4>
        <p class="mb-2 text-muted font-small">Items must arrive by:</p>
        <base-input
            title="Select Date"
            v-model="value.expiry"
            type="date"
            placeholder="Select Expiry Date">
        </base-input>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({
                return_policy: false,
                expiry: ''
            }),
        },
    },
}
</script>

<style>

</style>