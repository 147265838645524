<template>
<modal
    :show="show"
    :lg="true"
    :centered="true"
    :showFooter="true"
    :maxHeight="true"
    :showInfo="!!infoBtn"
    :infoText="infoBtn && infoBtn.text"
    :infoClass="infoBtn && infoBtn.color"
    :infoIcon="infoBtn && infoBtn.icon"
    @info="toggleTicket"
    :opaque="true"
    okText="UPDATE TICKET"
    @ok="submit"
    @cancel="cancel"
    okIcon="mdi mdi-recycle">

    <h5 class="m-0 font-small font-weight-bold" slot="header">
        View Ticket
    </h5>

    <div slot="body" class="p-2 pt-0 font-small">
        <div class="d-flex bg-default" v-if="ticket">
            <div class="bg-default-dark p-2">
                <span class="font-weight-bold d-block">#{{ ticket.ticket_number }}</span>

                <p class="font-xs text-muted mt-1 mb-1">{{ formatAge(ticket.created_at) }}</p>

                <span v-if="ticket.status" class="badge p-2 d-block" :class="`status-${ticket.status.name.toLowerCase()}`">
                    {{ ticket.status.name }}
                </span>
            </div>
            <div class="flex-grow-1 p-2">
                <p class="font-weight-bold" >
                    <span v-if="ticket.topic && ticket.topic.name == 'Other'">{{ ticket.other_topic }}</span>
                    <span v-else>{{ ticket.topic && ticket.topic.name }}</span>
                </p>

                <p class="text-muted text-justify">
                    {{ ticket.text }}
                </p>

                <div class="font-xs m-0">
                    Created by <span class="font-weight-bold">{{ ticket.created_by && ticket.created_by.fullname }}</span> on
                    <span>{{ ticket.created_at | formattedDateTime }}</span>
                </div>
            </div>
        </div>

        <hr/>

        <div class="chat-item" v-for="(update, index) in all" :key="index" :class="{'user': update.created_by.id == authUser.id}">
            <div class="user-img">
                <img :src="update.created_by.image_url"/>
            </div>
            <div class="chat p-2 rounded">
                <div class="mb-2">
                    <span class="font-weight-bold text-primary">{{ update.created_by.fullname }}</span>
                    <small class="ml-2">
                        <i class="mdi mdi-clock text-secondary"></i>
                        {{ update.created_at | formattedDateTime }}
                    </small>
                </div>
                <p class="text-left">{{ update.text }}</p>
            </div>
        </div>

        <div id="scrollhere" style="height:1px;width:100%;"></div>

        <empty title="No update available for this ticket" v-if="!all.length"/>
    </div>
</modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default{
    props: ['ticket_id'],

    data() {
        return {
            showOtherTopic: false,
            show: false,
            ticket: null
        }
    },

    computed: {
        ...mapGetters({
            all: 'Tickets/getAllUpdates',
            pageDetails: 'Tickets/getUpdatesPageDetails',
        }),

        status() {
            return this.ticket && this.ticket.status ? this.ticket.status.name : null
        },

        isClosed() {
            return this.status == 'Closed';
        },

        isOpen() {
            return this.status == 'Open'
        },

        infoBtn() {
            if (this.isClosed) return {
                text: 'Open Ticket',
                color: 'btn btn-success',
                icon: 'mdi mdi-refresh'
            };

            if (this.isOpen) return {
                text: 'Close Ticket',
                color: 'btn btn-warning',
                icon: 'mdi mdi-block-helper'
            };
        }
    },

    watch: {
        ticket_id() {
            if (this.ticket_id) {
                this.showTicket();
            } else {
                this.cancel()
            }
        }
    },

    methods: {
        ...mapActions({
            store: 'Tickets/store',
            index: 'Tickets/getUpdates',
            find: 'Tickets/show',
            update: 'Tickets/update'
        }),

        async toggleTicket() {
            let message, type;

            if (this.isClosed) {
                type = 'Open';
                message = `If you open this ticket, you will be able to post or receive updates on this ticket. Are you sure?`;
            } else {
                type = 'Close';
                message = `If you close this ticket, no one will be able to post or receive updates on this ticket. Are you sure?`;
            }

            let result = await this.swalConfirm(`${type} ticket #${this.ticket.ticket_number}?`, message, `Yes, I'm sure`);

            if (!result.value) {
                return;
            }

            this.showLoader(`${type == 'Open' ? 'Opening' : 'Closing'} ticket... Please Wait`);

            this.update({id: this.ticket_id})
                .then(response => {
                    this.hideLoader();
                    this.ticket = response.ticket;

                    if (response.message) {
                        this.swalSuccess(response.message);
                    }
                })
                .catch(error => {
                    this.hideLoader();
                })
        },

        scrollToBottom(element = 'scrollhere') {
            let bottom = document.getElementById(element);

            if (bottom) {
                bottom.scrollIntoView()
            }
        },

        showTicket() {
            this.showLoader('Getting ticket details... Please wait');

            this.find({id: this.ticket_id})
                .then(response => {
                    // this.hideLoader();

                    this.ticket = response;

                    this.loadData({clearState: true});
                })
                .catch(error => {
                    this.hideLoader();

                    if (error.message) {
                        this.swalError(`Oops`, error.message);
                    }
                })
        },

        cancel() {
            this.show = false;
            this.$emit('cancel');
        },

        submit() {
           this.$emit('update',
                this.ticket.topic && this.ticket.topic.name == 'Other'
                ? this.ticket.other_topic
                : this.ticket.topic && this.ticket.topic.name
           )
        },

        loadData(query) {
            let params = {
                ...query,
                ticket_id: this.ticket_id
            };

            this.showLoader('Fetching ticket updates... Please Wait')

            this.index(params)
                .then(response => {
                    this.hideLoader()
                    this.show = true;
                })
                .catch(error => {
                    this.hideLoader()
                })
        }
    },

    created() {

    }
}
</script>