import WordCloud from 'wordcloud';
import { removeStopwords, eng } from 'stopword';
import nlp from 'compromise';
import { LIGHT_COLORS, DARK_COLORS } from '../constants/misc';
const customStopwords = ['its'];

export const cleanText = (text) => {
    const regex = /[^a-zA-Z_\s]/g;
    return text.toLowerCase().replace(regex, '');
};

export const getWordFrequency = (tokens) => {
    let wordFrequency = {};

    tokens.forEach((token) => {
        if (token.length < 2) {
            return;
        }

        if (wordFrequency[token]) {
            wordFrequency[token] += 1;
        } else {
            wordFrequency[token] = 1;
        }
    });

    //sort by frequency
    wordFrequency = Object.entries(wordFrequency);

    return wordFrequency;
};

export const getWordCloudData = (wordFrequency) => {
    return wordFrequency.map(([word, freq]) => {
        let fontSize = 14 + freq * 2
        return [word, fontSize]
    });
};

export const drawWordCloud = (canvas, text) => {
    text = cleanText(text);

    let tokens = removeStopwords(text.split(' '), [...eng, ...customStopwords]);
    let adjectives = nlp(tokens.join(' ')).adjectives().out('array');
    let wordFrequency = getWordFrequency(adjectives);
    // console.log(wordFrequency);
    const options = {
        list: getWordCloudData(wordFrequency),
        gridSize: Math.round(16 * canvas.width / 1024),
        fontFamily: 'Times, serif',
        color: function (word, weight) {
            let colors = [];
            if (weight >= 16) {
                colors = DARK_COLORS;
            } else {
                colors = LIGHT_COLORS;
            }

            return colors[Math.floor(Math.random() * colors.length)];
        },
        rotateRatio: 0.5,
        rotationSteps: 3,
        shuffle: true,
        clearCanvas: true,
    };

    WordCloud(canvas, options)
};