<template>
    <responsive-width>
        <p-header _title="Products"/>

        <Products
            :ads="all"
            @viewAd="viewAd"/>

        <create-channel-info :mood="mood"/>
    </responsive-width>
</template>

<script>
import Products from '@/views/customer/products/Index.vue';
import { mapActions, mapGetters } from 'vuex';
import CreateChannelInfo from './partials/create-channel-info.vue';

export default {
    components: {
        Products,
        CreateChannelInfo
    },

    computed: {
        ...mapGetters({
            all: 'GettingStarted/getAll'
        }),

        mood() {
            return this.$route.params.mood;
        },

        productTypeIds() {
            return this.$route.params.ids;
        }
    },

    methods: {
        ...mapActions({
            getGuestAds: 'GettingStarted/getGuestAds'
        }),

        viewAd(id) {
            this.$router.push({ name: 'gs_product_detail', params: { id, mood: this.mood, ids: this.productTypeIds } })
        }
    },

    created() {
        this.setPheaderTitle('View Products');

        this.showLoader('Loading Products... Please wait');
        this.getGuestAds({
            ids: this.$route.params.ids,
            mood: this.$route.params.mood,
            clearState: true
        })
        .then(() => this.hideLoader())
        .catch(error => this.hideLoader());
    }
}
</script>

<style>

</style>