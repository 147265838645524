<template>
    <div class="form-group elevation mb-3 type-2" :class="divClass">
        <label class="font-small" v-if="!noLabel">{{label ? label : placeholder}}</label>

        <div class="icon" v-if="icon" :class="{'adjust': !!addon, 'error': !!error}">
            <span :class="`${icon} ${error ? 'shake-bell text-danger' : ''}`"></span>
        </div>

        <span class="font-xs error-label" v-if="error">{{ error }}</span>

        <div class="input-group">
            <div class="input-group-prepend" v-if="addon">
                <span class="input-group-text text-muted font-small" :class="{'error': !!error}" v-html="addon"></span>
            </div>

            <input
                :type="type"
                class="form-control font-small"
                :class="{'unpadded': !!addon, 'padded': !!error}"
                :disabled="disabled"
                :placeholder="placeholder"
                @input="onInput"
                :value="value"
                :name="name"
                :ref="reference"
            >
        </div>
    </div>
</template>

<script>

export default {
    props: {
        value: {
            type: [String, Number]
        },

        addon: String,

        name: {
            type: String
        },

        type: {
            type: String,
            default: 'text'
        },

        label: {
            type: String,
            default: ''
        },

        error: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        noLabel: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        reference: {
            type: String
        },

        divClass: {
            type: String
        },

        desc: {
            type: String
        },

        icon: String
    },

    data () {
        return {
            payload: ''
        }
    },

    methods: {
        /**
         * Emits typed value
         *
         * @param {Object} $event
         * @return {Undefined}
         */
        onInput($event) {
            this.$emit('input', $event.target.value);
        }
    }
}
</script>

<style>

</style>
