<template>
  <div>
    <popup-dialog
      v-if="show"
      @cancel="cancel">
      <view-comments :holdListItem="holdListItem"/>
    </popup-dialog>
  </div>
</template>

<script>
import bus from '@/event-bus';
import { EVENT_SHOW_COMMENTS } from '@/constants/events';
import PopupDialog from '@/components/shared/PopupDialog.vue';
import ViewComments from '@/views/customer/holdlists/comments/ViewComments.vue';

export default {
  components: {
    PopupDialog,
    ViewComments,
  },

  data() {
    return {
      show: false,
      holdListItem: null
    }
  },

  computed: {
  },

  methods: {
    cancel() {
      this.holdListItem = null;
      this.show = false;
    },
  },

  created() {
    bus.$on(EVENT_SHOW_COMMENTS, (holdListItem) => {
      // console.log('EVENT_SHOW_COMMENTS', holdListItem);
      this.show = true;
      this.holdListItem = holdListItem;
    });
  },

  beforeDestroy() {
    bus.$off(EVENT_SHOW_COMMENTS);
  }
}
</script>

<style>

</style>