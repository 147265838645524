import { mapActions } from 'vuex';
import { debounce } from 'lodash';
import bus from '../../event-bus'

export default {
    ...mapActions({
        showLoader: 'showLoader',
        hideLoader: 'hideLoader'
    }),

    setPheaderTitle(title) {
        bus.$emit('p-header-title', title)
    },

    setPheaderImage(url) {
        bus.$emit('p-header-image', url)
    },

    formatAge(value) {
        if (!value) return null;

        return moment(value).fromNow();
    },

    capitalize(value) {
        var value = value.toString();

        return value.charAt(0).toUpperCase() + value.slice(1);
    },

    /**
     * Shows a confirmation dialog with sweet alert
     *
     * @param {Object} confirmation
     * @param {Object} params
     * @returns {Object}
     */
    confirmDelete(confirmation, params) {
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, continue!'
            }).then((result) => {
                if (result.value) {
                    if (confirmation) {
                        confirmation(params)
                            .then(() => {
                                Swal.fire(
                                    'Done!',
                                    '',
                                    'success'
                                );
                            })
                            .catch(() => {
                                Swal.fire(
                                    'Failed!',
                                    '',
                                    'error'
                                );
                            })
                    }
                }
            })
        });
    },

    /**
     * Shows or hides modal
     *
     * @param {Boolean} show
     * @param {String} modalEl modal to be used, has priority if set
     * @param {Object} options additional options to be passed to modal
     * @returns {Undefined}
     */
    showModal(show, modalEl = null, options = {backdrop: 'static'}) {
        let modal = this.modalId;

        if (modalEl) {
            modal = modalEl
        }

        if (modal) {
            if (show) {
                $(`#${modal}`).modal(options)
            } else {
                $(`#${modal}`).modal('hide');
            }
        }
    },

    /**
     * Build html from an array, an object or a string containing errors.
     *
     * @param {Object|Array|String} errors
     * @return {Object}
     */
    buildErrors(errors) {
        let message = '';
        let title = 'User Error Alert';

        if (process.env.NODE_ENV !== 'production') {
            // console.log(errors);
        }

        if (errors.errors) {
            errors = errors.errors;
        } else if (errors.items) {
            errors = ['Sorry, the form validation failed. Fix all field errors.'];
        } else if (errors.error) {
            errors = [errors.error];
        } else if (typeof errors === 'object') {
            errors = Object.values(errors);
        } else {
            errors = ['Something unexpected happened.'];
        }

        message = '<ul>';

        $.each(errors, function (index, error) {
            message += `<li>${error}</li>`;
        });

        message += '<ul>';

        return message;
    },

    /**
     * Builds validation errors from server
     *
     * @param {Object} errors
     * @returns {Object}
     */
    buildValidationErrors(errors) {
        if (!this.hasOwnProperty('validationErrors')) {
            return console.error('You need to define validationErrors property');
        }

        for (let key of Object.keys(this.validationErrors)) {
            if (errors && errors[key] && errors[key].length) {
                this.validationErrors[key] = errors[key][0];
            } else {
                this.validationErrors[key] = '';
            }
        }
    },

    /**
     * Shows a sweet alert dialog
     *
     * @param {String} title
     * @param {String} text
     * @returns {Object}
     */
     swalConfirm(title = 'Are you sure?', text = 'Please confirm your action', okText = 'Yes', cancelText = 'Cancel!') {
         return Swal.fire({
             title,
             html: text,
             type: 'warning',
             showCancelButton: true,
             confirmButtonText: okText,
             cancelButtonText: cancelText,
             confirmButtonClass: 'btn elevation-2 btn-success mt-2 font-xs',
             cancelButtonClass: 'btn elevation-2 btn-danger ml-2 mt-2 font-xs',
             buttonsStyling: false
         });
     },

    /**
     * Shows sweetalert success popup
     *
     * @param {String} title
     * @param {String} text
     * @returns {Object}
     */
    swalSuccess(title = 'Success', text = '') {
        return Swal.fire({
            title,
            text,
            type: 'success',
            confirmButtonClass: 'btn elevation bg-success mt-2 font-xs',
        });
    },

    /**
     * Shows sweet alert error popup
     *
     * @param {String} title
     * @param {String} text
     * @param {Boolean} timer
     * @returns {Object}
     */
    swalError(title = 'Error', text = '', timer = true) {
        let options = {
            title,
            text,
            type: 'error',
            confirmButtonClass: 'btn elevation bg-danger mt-2 font-xs',
        };

        if (timer) {
            options.timer = 5000;
        }

        return Swal.fire(options);
    },

    /**
     * Shows sweet alert error popup
     *
     * @param {String} title
     * @param {String} text
     * @param {Boolean} timer
     * @returns {Object}
     */
    swalWarn(title = 'Error', text = '', timer = true) {
        let options = {
            title,
            text,
            type: 'warning',
            confirmButtonClass: 'btn btn-confirm mt-2',
        };

        if (timer) {
            options.timer = 5000;
        }

        return Swal.fire(options);
    },

    /**
     * Shows an error notification
     *
     * @param {String} text
     * @returns {Undefined}
     */
    notifySuccess(text, title = 'Well Done!') {

    },

    /**
     * Shows an error notification
     *
     * @param {String} text
     * @returns {Undefined}
     */
    notifyError(text, title = 'Oh snap!') {

    },

    /**
     * Shows an warning notification
     *
     * @param {String} text
     * @returns {Undefined}
     */
    notifyWarn(text, title = 'Heads up!') {

    },

    /**
     * Shows an info notification
     *
     * @param {String} text
     * @returns {Undefined}
     */
    notifyInfo(text, title = 'Heads up!') {

    },

    /**
     * Initiates a tour for page
     *
     * @returns {Undefined}
     */
    startTour(tour) {
        hopscotch.startTour(tour);
    },

    resizeTextarea() {
        function OnInput() {
            this.style.height = 'auto';
            this.style.height = (this.scrollHeight) + 'px';
        }

        const tx = document.getElementsByTagName('textarea');

        for (let i = 0; i < tx.length; i++) {
            tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;');
            tx[i].addEventListener("input", OnInput, false);
        }
    },

    isURL(string) {
        try {
            new URL(string);
        } catch (_) {
            return false;
        }

        return true;
    },

    toDashboard() {
        return this.$router.replace({ name: `dashboard` });
    },

    updateRouteQuery(query) {
        let current = `${new URLSearchParams({...this.$route.query})}`;
        let path = `${new URLSearchParams({...this.$route.query, ...query})}`;

        if (current != path) {
            this.$router.replace(`?${path}`);
        }
    },

    async uploadPresignedFile(file, url) {
        try {
            await fetch(url, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type,
                    'Cache-Control': 'public,max-age=31536000,immutable',
                    'x-amz-acl': 'public-read',
                }
            });

            return true;
        } catch (error) {
            return false;
        }
    },

    async getImageColorPalette(img) {
        try {
            const colorThief = new ColorThief();
            const main = colorThief.getColor(img);
            const others = colorThief.getPalette(img, 4);
            return Promise.resolve({ main, others });
        } catch (error) {
            return Promise.reject(error);
        }
    },
}