<template>
<div class="">
    <div class="row no-gutters">
        <div class="col-12 col-md-6 min-height-full pb-2" v-for="(feature, index) in features" :key="index">
            <div class="card elevation p-3 m-1 min-height-full no-radius">
                <div class="icon d-flex justify-content-center">
                    <!-- <img :src="feature.icon" class="avatar-xl circle border-secondary border bg-secondary p-1"/> -->
                    <div class="avatar-xl circle border-secondary border bg-secondary p-1 d-flex justify-content-center align-items-center">
                        <span :class="feature.icon" class="text-danger" style="font-size:4s0px;"></span>
                    </div>
                </div>
                <h5 class="font-xs text-center mb-0">{{ feature.title }}</h5>
                <hr class="mt-2 mb-2"/>
                <p class="text-justify">{{ feature.description }}</p>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    export default {
        computed: {
            features() {
                return [
                    {
                        icon: 'mdi mdi-comment-account-outline',
                        title: 'Feature title',
                        description: `feature description`,
                    },
                ];
            }
        }
    }
</script>