import Portal from '@/views/common/layouts/Master.vue';


let routes = [
    {
        path: '/common',
        name: 'common_module',
        component: Portal,
        meta: {
            requiresAuth: true
        },
        children: require('./common').default
    },

    {
        path: '/customer',
        name: 'customer_module',
        component: Portal,
        meta: {
            requiresAuth: true
        },
        children: require('./customer').default
    }
];

export default routes;