<template>
    <div>
        <p :class="className">
            {{ displayedText }}
            <a
                href="#"
                v-if="text.length > shortTextLength"
                @click.prevent="showFullText = !showFullText">
                {{ showFullText ? 'Read less' : 'Read more' }}
            </a>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },

        className: String
    },
    data() {
        return {
            showFullText: false,
            shortTextLength: 100
        };
    },
    computed: {
        displayedText() {
            if (this.showFullText || this.text.length <= this.shortTextLength) {
                return this.text;
            } else {
                return this.text.slice(0, this.shortTextLength) + '...';
            }
        }
    }
};
</script>
