<template>
    <div class="pt-5">
        <heading title="RESET YOUR PASSWORD" description="Elevate your shopping game"/>

        <div class="row justify-content-center mt-4">
            <div class="col-lg-4 col-md-6 col-12 pr-4 pl-4">
                <form class="pt-5" @submit.prevent="submit">
                    <base-input
                        label="Email"
                        required
                        icon="mdi mdi-email-outline"
                        v-model="credentials.email"
                        class="filter elevation"
                        placeholder=".e.g you@example.com"/>

                    <button type="submit" class="btn btn-success font-small pb-3 pt-3 btn-block mt-4 elevation-2">
                        RESET PASSWORD
                    </button>

                    <other-links :links="links"/>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import OtherLinks from './partials/PageLinks';
import Heading from './partials/GuestHeading';

export default {
    components: {
        Heading,
        OtherLinks
    },

    data() {
        return {
            credentials: {
                email: '',
            }
        }
    },

    computed: {
        links() {
            let links = [
                {
                    name: 'login',
                    path: '/login',
                    title: `Login instead?`,
                    text: `If you remembered your password then login now.`
                },
            ];

            return links;
        }
    },

    methods: {
        ...mapActions({
            forgotPassword: 'Authentications/forgotPassword'
        }),

        submit() {
            if (!this.credentials.email) {
                return this.swalError(`Oops`, `Enter the email you registered with`, false);
            }

            this.showLoader('Sending Reset Link... Please wait');

            this.forgotPassword(this.credentials)
                .then(response => {
                    this.hideLoader();

                    if (response.message) {
                        this.swalSuccess(response.message);
                    }

                    this.goHome();
                })
                .catch(error => {
                    this.hideLoader();
                    this.swalError('Oops', error.message, false);
                });
        },

        goHome() {
            // this.$router.push({name: 'dashboard'});
        }
    },

    created() {
        if (this.isLoggedIn) {
            this.goHome();
        }
    }
}
</script>