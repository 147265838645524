<template>
<div class="full-height">
    <app-loader v-if="loader.show" :text="loader.text"/>

    <transition name="router">
      <router-view/>
    </transition>

    <customer-global-popups/>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AppLoader from '@/components/layouts/AppLoader';
import TestingMessages from '../TestingMessages.vue';
import CustomerGlobalPopups from '@/views/customer/GlobalPopups.vue';

export default {
    components: {
        AppLoader,
        TestingMessages,
        CustomerGlobalPopups
    },

    computed: {
        ...mapGetters({
            loader: 'getLoader'
        })
    },

    methods: {
        ...mapActions({
            getCountries: 'Countries/index',
            getProductTypes: 'ProductTypes/index'
        })
    },

    created() {
        this.getCountries().then(response => null).catch(error => null);
        this.getProductTypes().then(response => null).catch(error => null);
    }
}
</script>

<style scoped>

</style>