<template>
    <div class="bg-default pt-5 pb-5">
        <heading title="CREATE YOUR WOONMI ACCOUNT" description="Elevate your shopping game"/>

        <div class="row justify-content-center">
            <div class="col-md-4 col-10">
                <steps :steps="steps" :active="currentStep" class="mt-4 mt-md-5"/>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-4 col-10">
                <form @submit.prevent="submit" class="mt-4">
                    <transition-group name="swipe">
                        <account
                            v-if="currentStep == 1"
                            :currentStep="currentStep"
                            :isLastStep="isLastStep"
                            :data="payload"
                            @next="next"
                            @prev="prev"
                            :key="3"
                            @submit="setAccountData"/>

                        <password
                            v-if="currentStep == 2"
                            class="mb-5"
                            :currentStep="currentStep"
                            :isLastStep="isLastStep"
                            @next="next"
                            :key="4"
                            @prev="prev"
                            @submit="tncAgreement"/>

                    </transition-group>

                    <other-links :links="links"/>
                </form>
            </div>
        </div>

        <terms :show="showTNC" @cancel="showTNC = false;" @agree="submit"/>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import OtherLinks from './partials/PageLinks';
import Heading from './partials/GuestHeading';
import Steps from './partials/MultiStep';
import BuyerType from './partials/steps/BuyerType.vue';
import Account from './partials/steps/Account';
import Password from './partials/steps/Password';
import Terms from './partials/steps/TNC';
import Merchant from './partials/steps/Merchant'

export default {
    components: {
        Terms,
        OtherLinks,
        Heading,
        Steps,
        BuyerType,
        Account,
        Password,
        Merchant
    },

    data() {
        return {
            payload: {
                buyer_type_id: ''
            },

            showTNC: false,

            currentStep: 1,
            buyerTypes: []
        }
    },

    computed: {
        ...mapGetters({
            countries: 'Countries/getAll'
        }),

        steps() {
            return [
                { id: 1, icon: 'mdi mdi-account-circle' },
                { id: 2, icon: 'mdi mdi-security' },
            ];
        },

        isLastStep() {
            return this.currentStep == this.steps.length;
        },

        links() {
            return [
                {
                    name: 'login',
                    path: '/login',
                    title: `Already have an account?`,
                    text: `Log into your existing account.`
                }
            ]
        }
    },

    watch: {
    },

    methods: {
        ...mapActions({
            register: 'Authentications/register',
            getBuyerTypes: 'Authentications/getBuyerTypes'
        }),

        setPayload(data) {
            this.payload = {
                ...this.payload,
                ...data
            };
        },

        setAccountData(data) {
            this.setPayload(data);

            this.next();
        },

        next() {
            if (this.currentStep < this.steps.length) {
                this.currentStep++;
            }
        },

        prev() {
            if (this.currentStep > 1) {
                this.currentStep--;
            }
        },

        tncAgreement(data) {
            this.setPayload(data);

            this.showTNC = true;
        },

        submit() {
            this.showTNC = false;

            this.showLoader('Creating your account.. Please wait');

            this.register({...this.payload })
                .then(response => {
                    this.hideLoader()

                    this.swalSuccess(`Account Created!`, `Your account has been created successfully. A email verification link has been sent to your email. Click on the link from your email to verify your account`, false);

                    this.$router.push({name: 'login'});
                })
                .catch(error => {
                    this.hideLoader();
                    this.swalError(`Attention Required`, `${error.message} Please fix all issues before proceeding.`)
                    this.buildValidationErrors(error.errors);
                })
        },
    },

    created() {
        this.getBuyerTypes()
            .then(response => {
                this.buyerTypes = response;
            })
            .catch(error => {
                console.log(error);
            })
    },

    mounted() {
        // this.showTNC = true;
    }
}
</script>