<template>
    <div>
        <div class="elevation m-1 mb-4 p-2 rounded" v-if="all.length">
            <h5 class="font-big text-primary text-center p-2">Summary of {{ type }} comments</h5>
            <div class="d-flex justify-content-center wordcloud-holder">
                <canvas id="wordcloud"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { drawWordCloud } from '@/libs/wordcloud';

export default {
    props: ['type'],
    computed: {
        ...mapGetters({
            all: 'HoldListComments/getAll',
        }),

        texts() {
            return this.all.map(item => item.text).join(' ').trim();
        }
    },

    watch: {
        all() {
            this.createWordCloud();
        }
    },

    methods: {
        createWordCloud: debounce(function () {
            const canvas = document.getElementById('wordcloud');
            if (!canvas) return;
            drawWordCloud(canvas, this.texts);
        }, 200),
    },
    mounted() {
        this.createWordCloud();
    },
}
</script>

<style></style>