export default {
    showLoader({ commit }, text) {
        commit('SHOW_LOADER', text);
    },

    hideLoader({ commit }) {
        commit('HIDE_LOADER');
    },

    closeSidebar({commit}) {
        commit('CLOSE_SIDEBAR');
    },

    openSidebar({commit}) {
        commit('OPEN_SIDEBAR');
    },

    setPHeaderTitle({commit}, title) {
        commit('SET_PHEADER_TITLE', title);
    }
}