<template>
    <div class="glide" ref="carousel">
        <div data-glide-el="track" class="glide__track">
            <ul class="glide__slides">
                <slot/>
            </ul>
        </div>
    </div>
</template>

<script>
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import Glide from '@glidejs/glide';

export default {
    props: {
        value: {
            type: Number,
            default: 0
        },
        loop: {
            type: Boolean,
            default: false
        },
        swippable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            instance: null,
        }
    },

    watch: {
        value() {
            if (this.instance) {
                this.instance.go(`=${this.value}`);
            }
        }
    },

    methods: {
        setUpGlide() {
            const config = {
                perView: 1,
                keyboard: false,
                animationDuration: 500,
                startAt: this.value,
                loop: this.loop,
                swipeThreshold: false,
                disabled: true,
            };

            if (!this.swippable) {
                config.swipeThreshold = false;
                config.dragThreshold = false;
            }

            this.instance = new Glide(this.$refs.carousel, config);

            this.instance.mount();
        },
    },

    mounted() {
        this.setUpGlide();
    }
}
</script>

<style>

</style>