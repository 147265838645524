<template>
    <div class="pros-cons mb-3">
        <button
            class="btn pros font-small"
            type="button"
            @click="setType(TYPE_PROS)"
            :class="{ active: value === TYPE_PROS }">
            <i class="mdi mdi-thumb-up-outline mr-1"></i>
            Pros
        </button>
        <button
            class="btn cons font-small"
            type="button"
            @click="setType(TYPE_CONS)"
            :class="{ active: value === TYPE_CONS }">
            <i class="mdi mdi-thumb-down-outline mr-1"></i>
            Cons
        </button>
    </div>
</template>

<script>
import { TYPE_PROS, TYPE_CONS } from '@/constants/holdList.js';

export default {
    props: ['value'],

    data() {
        return {
            TYPE_PROS,
            TYPE_CONS,
        }
    },

    methods: {
        setType(type) {
            this.$emit('input', type === this.value ? null : type);
        }
    },
}
</script>

<style>

</style>