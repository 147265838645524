<template>
    <div class="login-page v-stretch">
        <div class="row v-stretch">
            <div class="col-md-12 v-stretch d-flex flex-column justify-content-center align-items-center left p-5">
                <div class="mb-5 title">
                    <logo/>
                </div>

                <div class="socials text-center mb-3 mt-4">
                    <div class="buttons">
                        <button class="facebook elevation mr-3">
                            <i class="mdi mdi-facebook"></i>
                        </button>
                        <button class="google elevation">
                            <i class="mdi mdi-google"></i>
                        </button>
                    </div>
                </div>

                <div class="row justify-content-center w-100">
                    <div class="col-md-5">
                        <div class="row mb-4 mt-3 align-items-center">
                            <div class="col-5">
                                <div class="line"></div>
                            </div>
                            <div class="col-2 text-uppercase or text-center">Or</div>
                            <div class="col-5">
                                <div class="line"></div>
                            </div>
                        </div>

                        <form @submit.prevent="submit" method="POST">
                            <div class="form-group">
                                <i class="mdi mdi-account icon"></i>
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Username"
                                    name="username"
                                    v-model="credentials.username"/>
                            </div>
                            <div class="form-group">
                                <i class="mdi mdi-lock icon"></i>
                                <input
                                    class="form-control"
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    ref="password"
                                    v-model="credentials.password"/>
                                <button type="button" class="btn password-toggle" @click="togglePassword">
                                    <i class="mdi mdi-eye" v-if="!showPassword"></i>
                                    <i class="mdi mdi-eye-off" v-else></i>
                                </button>
                            </div>

                            <div class="d-flex justify-content-between mt-4">
                                <label class="cursor-pointer d-flex align-items-center text-black-fade">
                                    <input type="checkbox" name="remember" v-model="credentials.remember" class="mr-1"/>
                                    Remember Me
                                </label>
                                <a href="#">Forgot Password ?</a>
                            </div>

                            <div class="row justify-content-center mt-4">
                                <div class="col-8">
                                    <button type="submit" class="btn font-big sign-in btn-block elevation">
                                        Sign In
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Login',

    data() {
        return {
            showPassword: false,
            credentials: {
                username: '',
                password: '',
                remember: true,
            }
        }
    },

    methods: {
        ...mapActions({
            login: 'Authentications/login',
        }),

        signup() {
            this.$router.push({name: 'register'});
        },

        submit() {
            this.showLoader('Authenticating... Please wait');

            this.login(this.credentials)
                .then(response => {
                    this.hideLoader();
                    this.goHome();
                })
                .catch(error => {
                    this.hideLoader();
                    this.swalError('Authentication Failed', error.message, false);
                });
        },

        goHome() {
            let route = null;

            const redirect = this.$route.query.redirect;

            if (this.authUser.password_changed_at === null) {
                route = {name: 'secure_account', query: {redirect}};
            } else if (redirect) {
                route = redirect;
            } else {
                route = {name: `dashboard`};
            }

            if (route) {
                this.$router.push(route);
            }
        },

        togglePassword() {
            this.showPassword = !this.showPassword;

            if (this.showPassword) {
                this.$refs.password.setAttribute('type', 'text');
            } else {
                this.$refs.password.setAttribute('type', 'password');
            }
        }
    },

    created() {
        if (this.isLoggedIn) {
            this.goHome();
        }
    }
}
</script>