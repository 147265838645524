<template>
    <div class="info-card" :class="item.theme">
        <div class="icon-holder d-flex justify-content-between">
            <component class="icon elevation" :is="item.icon"/>
        </div>
        <div class="info">
            <p class="m-0 mb-1 font-xs text-muted name text-truncate">{{ item.title }}</p>
            <p class="m-0 font-xl font-weight-bold value">{{ item.value }}</p>
        </div>
    </div>
</template>
<script>
import Icons from '@/components/icons';

export default {
    props: ['item'],
    components: {
        ...Icons
    }
}
</script>