<template>
    <responsive-width>
        <div class="channels">
            <h1 class="page-title">{{ type === 'self' ? 'My' : 'Friends' }}<br />Hold Lists</h1>

            <div class="row">
                <div class="col-12 col-md-6" v-for="(holdlist, index) in all" :key="holdlist.id">
                    <hold-list-item
                        :holdlist="holdlist"
                        :index="index"
                        :type="type" />
                </div>
            </div>

            <scroll-loader
                :loader-method="loadNextPage"
                :loader-disable="!showLoadMore"
                v-if="pageDetails" />
        </div>
    </responsive-width>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HoldListItem from './partials/HoldListItem.vue';

export default {
    props: {
        type: {
            type: String,
            default: 'self'
        }
    },

    components: {
        HoldListItem
    },

    data() {
        return {
            showAddIntent: false,
            showViewIntent: false,
            intent: null,
            showFilter: false,
            showIntents: false,
            loading: false
        }
    },

    computed: {
        ...mapGetters({
            pageDetails: 'HoldLists/getPageDetails',
            all: 'HoldLists/getAll'
        })
    },

    watch: {
    },

    methods: {
        ...mapActions({
            index: 'HoldLists/index',
            show: 'HoldLists/show'
        }),

        filterStatus(status) {
            this.params.status = status;
            this.loadData({ clearState: true });
        },

        addEditIntent() {
            this.$router.push({ name: 'create_intent' });
        },

        performFiltering() {
            this.loadData({ clearState: true });
        },

        async showIntent(id, params = {}) {
            this.showLoader('Getting intent data... Please wait');

            try {
                let intent = await this.show({ id, params });
                this.hideLoader();
                return intent;
            } catch (error) {
                this.hideLoader();

                return null;
            }
        },

        async editIntent(intent) {
            if (intent) {
                intent = await this.showIntent(intent.id);
            }

            this.intent = intent;
            this.showAddIntent = !!intent;
        },

        async viewIntent(intent) {
            if (intent) {
                intent = await this.showIntent(intent.id, { with_attributes: 1 });
            }

            this.intent = intent;
            this.showViewIntent = !!intent;
        },

        loadNextPage() {
            if (this.showLoadMore) {
                this.loadData(this.loadMore);
            }
        },

        loadData(query) {
            let params = {
                ...query,
                type: this.type,
                paginate: true,
                per_page: 10,
            }

            this.loading = true;

            this.index(params)
                .then(response => {
                    // console.log(this.all);
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                })
        }
    },

    created() {
        this.loadData({ clearState: true });
        this.setPheaderTitle((this.type === 'self' ? 'My' : 'Friends') + ' Hold Lists');
    }
}
</script>

<style></style>