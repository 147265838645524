<template>
  <div class="create-channel">
    <p-header _title="Create Your Channel"/>

    <responsive-width>
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <p class="mb-1 text-muted">Create your own channel from below.</p>
          <p class="text-muted font-small d-flex justify-content-start align-items-center mb-4 ml-2">
            Learn about
            <a
              @click="onOpenTerms"
              href="https://woonmi.com"
              class="font-small ml-1"
              target="_blank">Woonmi Channels</a>
          </p>

          <form @submit.prevent="submit">
            <base-input
              name="email"
              placeholder="Enter Your Email"
              label="Your Email"
              autocomplete="email"
              v-model="payload.email"/>

            <base-input
              name="name"
              placeholder="Enter Channel Name"
              label="Channel Name"
              v-model="payload.channel_name"/>

            <div class="product-types">
              <div class="pt-group" v-for="(group, index) in chunkedPTypes" :key="index">
                <span class="product-type truncate" v-for="pt in group" :key="pt.id">
                  {{ pt.name  }}
                </span>
              </div>
            </div>

            <div class="mt-3 mb-3">
              <hr/>
              <h5 class="font-small font-weight-bold mb-1">
                <i class="mdi mdi-eye-off mr-2"></i> Your data belongs to you.
              </h5>
              <p class="font-xs text-muted">
                Woonmi doesn't sell your data to advertisers or other third parties. We only use your data to provide you with the best experience possible.
              </p>
              <hr/>
            </div>

            <p class="font-small d-flex justify-content-start align-items-center mb-3">
                <input v-model="payload.agreement" type="checkbox" name="agreement" class="mr-2"/>
                I agree to the <a @click="onOpenTerms" href="https://woonmi.com" class="font-small ml-1" target="_blank">Terms of Service and Privacy Policy</a>
              </p>

            <div class="border-wrap">
                <button type="submit" class="btn create-account btn-block">
                    Submit
                </button>
            </div>
          </form>
        </div>
      </div>
    </responsive-width>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PHeader from '../../../components/shared/PHeader.vue'
import { chunk } from 'lodash';

export default {
  components: { PHeader },
  data() {
    return {
      clickedOnTerms: true,
      productTypes: [],
      payload: {
        email: '',
        channel_name: '',
        agreement: false
      }
    }
  },
  computed: {
    chunkedPTypes() {
      return chunk(this.productTypes, 3);
    },
  },
  methods: {
    ...mapActions({
      getProductTypes: 'ProductTypes/index',
      register: 'Authentications/gettingStarted'
    }),

    onOpenTerms() {
      this.clickedOnTerms = true;
    },

    submit() {
      if (!this.payload.email) {
        return this.swalError('Email required', 'Please enter your email', false)
      }

      if (!this.payload.channel_name) {
        return this.swalError('Channel name required', 'Please enter your channel name', false)
      }

      if (!this.payload.agreement) {
        return this.swalError('Terms & conditions required', 'Please agree to the terms of service and privacy policy', false)
      }

      if (!this.clickedOnTerms) {
        return this.swalError('Must read', 'You need to click on the link to terms of service and privacy policy', false)
      }

      this.showLoader('Creating channel. Please wait...')

      this.register({
        ...this.payload,
        mood: this.$route.params.mood,
        product_types: this.$route.params.ids.split('-'),
      }).then(response => {
          this.hideLoader();
          this.$router.push({
            name: 'create_intent',
            query: { intid: response.intent_id, ptids: this.$route.params.ids }
          })
        })
        .catch(error => {
          this.hideLoader()
          this.swalError('Error', error.message, false)
        })
    }
  },

  created() {
    this.setPheaderTitle('Create Channel')

    this.getProductTypes({ids: this.$route.params.ids})
    .then(response => {
      this.productTypes = response;

      if (this.productTypes.length > 0) {
        this.payload.channel_name = this.productTypes[0].name;

        if (this.productTypes.length > 1) {
          this.payload.channel_name += ` + ${this.productTypes.length - 1} more`;
        }
      }
    })
      .catch(error => console.log(error));
  }
}
</script>

<style>

</style>