export const LIGHT_COLORS = [
    "#FF6B6B", // Light Coral
    "#81DAF5", // Light Sky Blue
    "#FFEC8B", // Light Goldenrod
    "#E3C6E3", // Light Thistle
    "#96CE7F", // Light Green
    "#FFA07A", // Light Salmon
    "#B0C4DE", // Light Steel Blue
    "#FFB6C1", // Light Pink
    "#AFEEE2", // Light Turquoise
    "#FFDAB9", // Light Peach
    "#C2DFFF", // Light Periwinkle
    "#FFCCCB", // Light Rose
    "#FAD02E", // Light Yellow
    "#D8BFD8", // Thistle
    "#F5E0B7", // Light Wheat
    "#F0E68C", // Khaki
    "#FFE4E1", // Misty Rose
    "#D1E8E2", // Light Blue Green
    "#F4A460", // SandyBrown
    "#FFDEAD"  // NavajoWhite
];

export const DARK_COLORS = [
    '#4b2c54',
    '#a61367',
    '#3b3b98',
    '#0e0e52',
    '#2c2c54',
    '#14A4EB',
    '#7F57FF',
    '#fe4a49'
];