<template>
    <comments
        :hold_list_id="hold_list_id"
        :holdListItem="holdListItem"
        :comment_type="type"
        :readOnly="true"/>  
</template>

<script>
import Comments from '@/views/customer/holdlists/comments/ViewComments.vue';
import { mapActions } from 'vuex';

export default {
    props: {
        holdList: {
            type: Object,
        },
        type: {
            type: String,
            default: null,
        },
        holdListItem: {
            type: Object,
            default: null,
        },
    },

    components: {
        Comments,
    },

    computed: {
        hold_list_id() {
            return this.holdList ? this.holdList.id : null;
        }
    },

    methods: {
        ...mapActions({
            index: 'HoldListComments/index'
        }),
    }
}
</script>

<style></style>