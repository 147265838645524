<template>
    <div>
        <div class="row flex-nowrap set-holdlist-rankings no-scrollbar">
          <div
              class="col-md-3 col-8 p-0"
              v-for="item in items"
              :key="item.id">
              <set-ranking-item
                :holdListItem="item"
                :rank="ranks[item.id]"
                :isHoldlistOwner="isHoldlistOwner"
                @setRank="rank => setRank(rank, item.id)"/>
          </div>
        </div>
  </div>
</template>

<script>
import SetRankingItem from './SetRankingItem.vue'
export default {
  components: { SetRankingItem },
    props: {
        items: {
            type: Array,
            required: true
        },
        value: {
            type: Object,
            default: () => ({}),
            required: true
        },
        isHoldlistOwner: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ranks() {
            return this.invertKeyValues(this.value);
        }
    },

    methods: {
        setRank(rank, id) {
            let newVal = {...this.value};

            if (this.value[rank] === id) {
                delete newVal[rank];
            } else {
                if (this.ranks[id]) {
                    delete newVal[this.ranks[id]];
                }

                newVal[rank] = id;
            }


            this.$emit('input', { ...newVal })
        },

        invertKeyValues(obj) {
            let newObj = {};

            Object.entries(obj).forEach(([rank, id]) => {
                newObj[id] = parseInt(rank);
            });

            return newObj;
        }
    }
}
</script>

<style>

</style>