<template>
  <responsive-width>
    <div class="top-section">
        <div class="filter-buttons justify-start no-scrollbar mb-1">
            <button
                v-for="(productType, index) in productTypes"
                :key="index"
                class="btn font-small"
                :class="{active: activeProductType === productType.id}"
                @click="activeProductType = productType.id">
                {{productType.name}}
            </button>
        </div>

        <div class="holdlist-nav mb-3">
            <button
                type="button"
                class="btn"
                v-for="(nav, index) in filteredNavBtns"
                :key="index"
                :class="{ active: activeTab === nav.id }"
                @click="activeTab = nav.id">
                <i class="icon font-big" :class="nav.icon" v-if="nav.icon"></i>
                <span class="name font-xs">{{ nav.name }}</span>
            </button>
        </div>
    </div>

    <carousel v-model="activeStep">
        <carousel-item>
            <div v-if="isHoldlistOwner && activeTab === 'analytics'">
                <analytics :holdList="holdList"/>
            </div>
        </carousel-item>

        <carousel-item>
            <div class="p-1" v-if="activeTab === 'comments'">
                <comments
                    :items="items"
                    :ranks="ranks"
                    @unhold="unhold"
                    :isHoldlistOwner="isHoldlistOwner"/>
            </div>
        </carousel-item>

        <carousel-item>
            <div class="" v-if="activeTab === 'features'">
                <features
                    :items="items"
                    v-model="ranks"
                    :isHoldlistOwner="isHoldlistOwner"/>
            </div>
        </carousel-item>

        <carousel-item>
            <div class="p-1" v-if="isHoldlistOwner && activeTab === 'share'">
                <share-holdlist
                    :holdList="holdList"
                    v-if="holdList"
                    :key="remountes.share"
                    :guest="guest"
                    @shared="remount('share')"/>
            </div>
        </carousel-item>
    </carousel>
    <view-comments/>
  </responsive-width>
</template>

<script>
import { get, first } from 'lodash';
import { mapActions } from 'vuex';
import Carousel from '@/components/shared/carousel/Carousel.vue';
import CarouselItem from '@/components/shared/carousel/CarouselItem.vue';
import Comments from '@/views/customer/holdlists/comments/Index.vue';
import Features from '@/views/customer/holdlists/features/Index.vue';
import ViewComments from '@/views/customer/holdlists/comments/ViewCommentsPopup.vue';
import Analytics from '@/views/customer/holdlists/analytics/Index.vue';
import ShareHoldlist from '@/views/customer/holdlists/share/Index.vue';

export default {
    props: {
        hold_list_id: {
            type: [Number, String],
            required: true
        },
        type: {
            type: String,
            default: 'self'
        },
        guest: {
            type: String,
            default: null
        }
    },

    components: {
        Features,
        Comments,
        Carousel,
        CarouselItem,
        ViewComments,
        Analytics,
        ShareHoldlist,
    },

    data() {
        return {
            activeTab: null,
            activeProductType: null,
            ranks: {},

            holdList: null,
            navBtns: [
                {
                    id: 'analytics',
                    name: 'Analytics',
                    icon: 'mdi mdi-chart-pie',
                },
                {
                    id: 'comments',
                    name: 'Comments',
                    icon: 'mdi mdi-comment-multiple-outline'
                },
                {
                    id: 'features',
                    name: 'Features',
                    icon: 'mdi mdi-tag-multiple'
                },
                {
                    id: 'share',
                    name: 'Share',
                    icon: 'mdi mdi-share-variant'
                },
            ],

            remountes: {
                share: 0
            }
        }
    },

    computed: {
        activeStep() {
            return this.navBtns.findIndex(
                btn => btn.id === this.activeTab
            );
        },

        filteredNavBtns() {
            if (!this.isHoldlistOwner) {
                const tabs = ['comments', 'features'];
                return this.navBtns.filter(btn => tabs.includes(btn.id));
            }

            return this.navBtns;
        },

        items() {
            const intent_id = get(this.holdList, 'intent.id', null);
            const user_id = get(this.holdList, 'user_id', null);

            return get(this.holdList, 'items', []).map(item => {
                return {
                    ...item,
                    intent_id,
                    user_id
                }
            });
        },

        productTypes() {
            const defaultVal = { id: null, name: 'All' };
            const productTypes = get(this.holdList, 'intent.product_types', []);
            return [defaultVal, ...productTypes];
        },

        nextStep() {
            const lastIndex = this.navBtns.length - 1;

            if (this.activeStep === lastIndex) {
                return this.navBtns[0];
            }

            return this.navBtns[this.activeStep + 1];
        },

        prevStep() {
            const lastIndex = this.navBtns.length - 1;

            if (this.activeStep === 0) {
                return this.navBtns[lastIndex];
            }

            return this.navBtns[this.activeStep - 1];
        },

        isHoldlistOwner() {
            return this.holdList && this.holdList.user_id === this.authUser.id;
        }
    },

    watch: {
        ranks: {
            handler() {
                this.updateHoldlist()
            },
            deep: true
        },

        activeTab() {
            this.loadData();
        },

        isHoldlistOwner() {
            if (this.isHoldlistOwner) {
                this.activeTab = 'analytics'
            } else {
                this.activeTab = 'comments'
            }
        }
    },

    methods: {
        ...mapActions({
            show: 'HoldLists/show',
            update: 'HoldLists/update',
        }),

        remount(tab) {
            this.remountes[tab] += 1;
        },

        updateHoldlist() {
            const params = {
                id: this.hold_list_id,
                data: {
                    ranks: this.ranks
                }
            }

            this.update(params)
                .then(response => {
                    this.holdList = response;
                })
                .catch(() => {
                    this.hideLoader();
                });
        },

        unhold(holdListItemId) {
            this.holdList = {
                ...this.holdList,
                items: this.holdList.items.filter(item => item.id !== holdListItemId)
            }
        },

        loadData() {
            this.showLoader(`Loading hold list... Please wait`);

            const params = { id: this.hold_list_id, params: { type: this.type }}

            this.show(params)
                .then(response => {
                    this.holdList = response;
                    this.initRanks();
                    // console.log(this.holdList);
                    this.hideLoader();
                })
                .catch(() => {
                    this.hideLoader();
                });
        },

        initRanks() {
            const ranks = {};
            for (let item of this.items) {
                if (item.rank) {
                    ranks[item.rank] = item.id;
                }
            }
            this.ranks = ranks;
        }
    },
    created() {
        this.setPheaderTitle('View Hold Lists');
        this.activeTab = first(this.filteredNavBtns, {}).id;
    }
}
</script>

<style>

</style>