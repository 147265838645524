<template>
    <div class="create-intent-new">
        <responsive-width :noPadding="true">
            <div class="back-skip">
                <button class="btn back" @click="goPrev">
                    <i class="mdi mdi-chevron-left"></i>
                </button>
                <button class="btn back font-xs" @click="submit">
                    Finish
                </button>
            </div>

            <div class="text-center mb-4">
                <span class="font-weight-bold">{{ progress.current }}</span>/<span>{{ progress.total }}</span>
            </div>

            <carousel v-model="stepIndexes[activeStep]">
                <carousel-item>
                    <div class="step" v-if="activeStep === 'product_types'">
                        <div class="mb-3">
                            <product-types v-model="payload.product_types"/>
                        </div>
                    </div>
                </carousel-item>

                <carousel-item>
                    <div class="step" v-if="activeStep === 'brands'">
                        <brands :productTypes="payload.product_types" v-model="payload.brands"/>
                    </div>
                </carousel-item>

                <carousel-item>
                    <div class="step" v-if="activeStep === 'colors'">
                        <colors :productTypes="payload.product_types" v-model="payload.colors" />
                    </div>
                </carousel-item>

                <carousel-item>
                    <div class="step" v-if="activeStep === 'sizes'">
                        <sizes :productTypes="payload.product_types" v-model="payload.sizes"/>
                    </div>
                </carousel-item>

                <carousel-item>
                    <div class="step" v-if="activeStep === 'materials'">
                        <materials
                            :productTypes="payload.product_types"
                            v-model="payload.materials"/>
                    </div>
                </carousel-item>

                <carousel-item>
                    <div class="step" v-if="activeStep === 'product_features'">
                        <features
                            :productTypes="payload.product_types"
                            v-model="payload.features"/>
                    </div>
                </carousel-item>

                <carousel-item>
                    <div class="step" v-show="activeStep === 'product_preferences'">
                        <preferences
                            :productTypes="payload.product_types"
                            v-model="payload.prod_prefs"/>
                    </div>
                </carousel-item>

                <carousel-item>
                    <div class="step" v-show="activeStep === 'vendor'">
                        <vendor
                            :productTypes="payload.product_types"
                            v-model="payload.vendor"/>
                    </div>
                </carousel-item>

                <carousel-item>
                    <div class="step" v-if="activeStep === 'finalize'">
                        <finalize v-model="payload.finalize"/>
                    </div>
                </carousel-item>
            </carousel>

            <div class="navigation-btns mt-4">
                <next-btn :progress="progress" @goNext="goNext"/>
            </div>
        </responsive-width>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ProductTypes from './partials/productTypes.vue';
import Brands from './partials/brands.vue';
import Colors from './partials/colors.vue';
import Sizes from './partials/sizes.vue';
import Materials from './partials/Materials.vue';
import Features from './partials/features.vue';
import Preferences from './partials/preferences.vue';
import Vendor from './partials/vendor.vue';
import Finalize from './partials/finalize.vue';
import NextBtn from './partials/next-btn.vue';
import Carousel from '@/components/shared/carousel/Carousel.vue';
import CarouselItem from '@/components/shared/carousel/CarouselItem.vue';

export default {
    props: ['top', 'isGuest'],

    components: {
        Carousel,
        CarouselItem,
        NextBtn,
        Sizes,
        Brands,
        Colors,
        Vendor,
        Features,
        Finalize,
        Materials,
        Preferences,
        ProductTypes,
    },

    data() {
        return {
            activeStep: 'product_types',

            payload: {
                product_types: [],
                brands: {},
                colors: {},
                sizes: {},
                materials: {},
                features: {},
                prod_prefs: [],
                vendor: {
                    prefs: [],
                    rating: '',
                },
                finalize: {
                    return_policy: false,
                    expiry: '',
                },
            },
        }
    },

    computed: {
        ...mapGetters({
            colors: 'Intents/getColors',
            product_types: 'ProductTypes/getAll',
        }),

        progress() {
            return {
                current: this.stepIndexes[this.activeStep] + 1,
                total: Object.keys(this.stepIndexes).length
            }
        },

        stepIndexes() {
            return {
                product_types: 0,
                brands: 1,
                colors: 2,
                sizes: 3,
                materials: 4,
                product_features: 5,
                product_preferences: 6,
                vendor: 7,
                finalize: 8,
            }
        },

        productTypesFromQueries() {
            if (!this.$route.query.ptids) return [];

            return this.$route.query.ptids.split('-').map(id => parseInt(id));
        }
    },

    watch: {
        product_types() {
            if (this.productTypesFromQueries.length) {
                this.payload.product_types = this.productTypesFromQueries.map(id => {
                    return this.product_types.find(pt => pt.id === id);
                });
            }
        }
    },

    methods: {
        ...mapActions({
            store: 'Intents/store'
        }),

        skip() {
            this.goNext();
        },

        findPrevOrNext(step) {
            let descriptor = null;
            let destination = this.stepIndexes[this.activeStep] + step;

            const steps = Object.entries(this.stepIndexes);

            for (let [name, index] of steps) {
                if (index === destination) {
                    descriptor = { name, index };
                    break;
                }
            }

            return descriptor;
        },

        goPrev() {
            const prev = this.findPrevOrNext(-1);

            if (prev) {
                this.setActiveStep(prev.name);
            } else {
                if (this.payload.product_types.length) {
                    this.swalConfirm(
                        'Are you sure you want to cancel?',
                        'You will lose all your progress'
                    ).then(res => {
                        if (res.value) {
                            this.$router.go(-1);
                        }
                    });
                } else {
                    this.$router.go(-1);
                }
            }
        },

        goNext() {
            const next = this.findPrevOrNext(1);

            if (this.payload.product_types.length === 0) {
                return this.swalError('Please select at least one product type');
            }

            if (next) {
                this.setActiveStep(next.name);
            } else {
                this.submit();
            }
        },

        setActiveStep(name) {
            this.$nextTick().then(() => {
                this.activeStep = name;
            });
        },

        submit() {
            if (!this.payload.product_types.length) {
                return this.swalError('Please select at least one product type');
            }

            let name = this.payload.product_types[0].name;

            if (this.payload.product_types.length > 1) {
                name += ` + ${this.payload.product_types.length - 1} more`;
            }

            const data = {
                name,
                ...this.payload,
                product_types: this.payload.product_types.map(pt => pt.id),
            };

            this.showLoader('Creating intent... Please wait');

            this.store(data).then(response => {
                this.hideLoader();

                return this.$router.replace({
                    name: 'view_intent',
                    params: {
                        intent_id: response.id
                    }
                });
            })
            .catch(error => {
                this.hideLoader();
            })
        },
    },

    mounted() {
        if (this.$route.query.ptids && this.$route.query.intid) {
            this.activeStep = 'brands';
        }
    },

    created() {
        this.setPheaderTitle('Create new Intent');
    }
}
</script>
