<template>
  <div>
    <div class="search-form">
        <div class="form-group">
            <input
                type="text"
                v-model="keyword"
                class="form-control"
                placeholder="Search connections"/>

            <button type="submit" class="btn sf-submit">
                <i class="mdi mdi-magnify"></i>
            </button>
        </div>
    </div>

    <template v-if="showSelectedConns">
        <div class="m-0 font-small text-black-fade d-flex align-items-center">
            Share With <i class="mdi mdi-chevron-down"></i>
        </div>

        <div class="row pb-3 no-scrollbar m-0 flex-nowrap" style="overflow-x: auto;">
            <div class="col-3 col-md-2 p-1" v-for="(conn, index) in selectedConns" :key="index">
                <connection-item
                    @click="selectConn(conn)"
                    :conn="conn"
                    :shared="shares[conn.friend_id]"
                />
            </div>
        </div>

        <div class="row mt-2 mb-3">
            <div class="col-12 col-md-4">
                <btn-with-border-gradient @click="shareSelected">
                    <span class="font-small">Share With Selected Connections</span>
                </btn-with-border-gradient>
            </div>
        </div>
    </template>

    <template v-if="all.length">
        <div class="m-0 font-small text-black-fade d-flex align-items-center">
            Recent connections <i class="mdi mdi-chevron-down"></i>
        </div>

        <div class="row p-1 m-0">
            <div class="col-3 col-md-2 p-1" v-for="(conn, index) in all" :key="index">
                <connection-item
                    @click="selectConn(conn)"
                    :conn="conn"
                    :active="!!conns[conn.id]"
                    :shared="shares[conn.friend_id]"/>
            </div>
        </div>
    </template>

    <empty title="No possible connection(s) found" v-if="!all.length" />

    <div class="text-center mt-3 mb-5" v-if="pageDetails.currentPage < pageDetails.lastPage">
        <a href="#" @click.prevent="loadNextPage" class="font-small load-more">Load more...</a>
    </div>

    <div class="row mt-2" v-if="showShare">
        <div class="col-12 col-md-4">
            <btn-with-border-gradient @click="shareAll">
                <span class="font-small">
                    Share with All Connections
                </span>
            </btn-with-border-gradient>
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce, omit, get } from 'lodash';
import ConnectionItem from '@/views/customer/holdlists/share/partials/ConnectionItem.vue';

export default {
    props: {
        holdList: {
            type: Object,
            required: true
        },
        guest: {
            type: String
        }
    },

    components: {
        ConnectionItem
    },

    data() {
        return {
            keyword: '',
            conns: {},
        }
    },

    computed: {
        ...mapGetters({
            all: 'HoldLists/getAllConnections',
            pageDetails: 'HoldLists/getConnectionsPageDetails'
        }),

        shares() {
            return this.holdList.shares.reduce((acc, share) => {
                acc[share.friend_id] = true;
                return acc;
            }, {});
        },

        selectedConns() {
            return Object.values(this.conns);
        },

        showShare() {
            return this.pageDetails.total > 0;
        },

        showSelectedConns() {
            return Object.keys(this.conns).length > 0;
        }
    },

    watch: {
        keyword() {
            this.beginSearch();
        },
    },

    methods: {
        ...mapActions({
            getConnections: 'HoldLists/getConnections'
        }),

        shareSelected() {
            const data = {
                users: this.selectedConns.map(conn => get(conn, 'friend.username', conn.friend_id)),
            };

            this.$emit('share', data);
            this.conns = {};
        },

        shareAll() {
            const data = {
                users: [],
                connections: true
            };

            this.$emit('share', data);
            this.keyword = '';
        },

        selectConn(conn) {
            if (this.conns[conn.id]) {
                this.$set(this, 'conns', omit(this.conns, conn.id))
            } else {
                this.$set(this.conns, conn.id, conn);
            }
        },

        beginSearch: debounce(function() {
            this.loadData({ clearState: true });
        }, 500),

        loadNextPage() {
            if (this.showLoadMore) {
                this.loadData(this.loadMore);
            }
        },

        loadData(query) {
            query = {
                ...query,
                keyword: this.keyword,
                per_page: 12,
                hold_list_id: this.holdList.id,
                user_id: this.guest
            }

            this.getConnections(query)
            .then(response => {
                // console.log(this.all);
            });
        }
    },

    created() {
        this.loadData({ clearState: true});
    }
}
</script>

<style>

</style>