<template>
  <div style="padding-bottom: 30vh;">
    <div class="m-0 font-small text-black-fade d-flex align-items-center">
        Enter users' username, email or phone number <br/> (Hit return key to add more) <i class="mdi mdi-chevron-down"></i>
    </div>
    <base-token-input
        name="usernames"
        :disabled="true"
        placeholder="Enter users here"
        :typeahead="typeahead"
        v-model="usernames"
    />

    <div class="row mt-2" v-if="usernames.length">
        <div class="col-12 col-md-4">
            <btn-with-border-gradient @click="share">
                <span class="font-small">Share Holdlist</span>
            </btn-with-border-gradient>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            usernames: []
        }
    },

    computed: {
        typeahead() {
            return {
                url: `users/search`,
                options: {
                    name: 'username',
                    display: 'username'
                }
            };
        },
    },

    watch: {
        // usernames() {
        //     console.log(this.usernames);
        // }
    },

    methods: {
        share() {
            this.$emit('share', {
                users: this.usernames
            });

            this.usernames = [];
        }
    }
}
</script>

<style>

</style>