<template>
    <div class="getting-started" :class="{'normal': activeStep > 0}">
        <carousel v-model="activeStep">
            <carousel-item>
                <intro @next="goNext" v-if="activeStep === 0"/>
            </carousel-item>

            <carousel-item>
                <mood
                    @setMood="md => mood = md"
                    @next="goNext"
                    v-if="activeStep === 1"
                />
            </carousel-item>

            <carousel-item>
                <objective
                    @next="goNext"
                    v-model="objective"
                    v-if="activeStep === 2"
                />
            </carousel-item>

            <carousel-item>
                <product-types
                    @get-started="getStarted"
                    v-model="productTypes"
                    v-if="activeStep === 3"
                />
            </carousel-item>

            <carousel-item>
                <interests
                    @get-started="getStarted"
                    :interests="allInterests"
                    v-model="interests"
                    v-if="activeStep === 4"
                />
            </carousel-item>
        </carousel>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { debounce } from 'lodash';
import Mood from '@/views/customer/getting-started/steps/Mood.vue';
import Intro from '@/views/customer/getting-started/steps/Intro.vue';
import Interests from '@/views/customer/getting-started/steps/Interests.vue';
import Carousel from '@/components/shared/carousel/Carousel.vue';
import CarouselItem from '@/components/shared/carousel/CarouselItem.vue';
import ProductTypes from '@/views/customer/getting-started/steps/ProductTypes.vue';
import Objective from '@/views/customer/getting-started/steps/Objective.vue';

export default {
    components: {
        Mood,
        Intro,
        Carousel,
        Objective,
        ProductTypes,
        CarouselItem,
        Interests,
    },

    data() {
        return {
            activeStep: 0,
            allInterests: [],

            mood: '',
            objective: '',
            productTypes: [],
            interests: [],
        }
    },

    watch: {
        activeStep() {
            this.setRouteQuery();
        },
        mood() {
            console.log(this.mood)
            this.setRouteQuery();
        },
        objective() {
            this.setRouteQuery();
        },
    },

    methods: {
        ...mapActions({
            getInterests: 'Interests/getTypes'
        }),

        setRouteQuery: debounce(function () {
            this.updateRouteQuery({
                index: this.activeStep,
                mood: this.mood,
                objective: this.objective,
            });
        }, 100),

        goNext(index) {
            if (index > -1) {
                this.activeStep = index;
            } else {
                this.activeStep++;
            }
        },

        getStarted() {
            const route = {
                name: 'product_liting',
                params: { mood: this.mood },
                query: {}
            };

            if (this.mood === 'intent') {
                route.params.ids = this.productTypes.map(pt => pt.id);
                route.query.objective = this.objective;
            } else {
                route.params.ids = this.interests.map(i => i.id);
            }

            route.params.ids = route.params.ids.join(',');

            this.$router.push(route);
        },

        loadInterests() {
            this.getInterests()
                .then(response => {
                    this.allInterests = response;
                    // console.log(response)
                })
                .catch(error => {
                    console.log(error);
                })
        },
    },

    created() {
        this.activeStep = parseInt(this.$route.query.index) || 0;
        this.mood = this.$route.query.mood || '';
        this.objective = this.$route.query.objective || '';

        this.loadInterests();
    }
}
</script>