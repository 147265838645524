<template>
    <div class="hold-list-comments">
        <div class="admedia" v-if="holdListItem">
            <button @click="go(-1)" class="btn go-left elevation-2 circle">
                <i class="mdi mdi-chevron-left"></i>
            </button>

            <button @click="go(1)" class="btn go-right elevation-2 circle">
                <i class="mdi mdi-chevron-right"></i>
            </button>

            <carousel v-model="activeItem" :loop="true" :swippable="true">
                <carousel-item v-for="(media, index) in advertMedia" :key="index">
                    <img :src="media.file_url" alt="admedia">
                </carousel-item>
            </carousel>

            <div class="footer">
                <button class="btn-cancel elevation-2" @click="$emit('cancel')">
                    <i class="mdi mdi-close"></i>
                </button>
            </div>
        </div>

        <div class="comments">
            <comment v-for="comment in all" :key="comment.id" :comment="comment" />

            <comment v-for="i in loading" :key="`l-${i}`" :loader="true" />

            <empty title="No comment found" v-if="!all.length && readOnly"/>

            <scroll-loader :loader-method="loadNextPage" :loader-disable="!showLoadMore" v-if="pageDetails" />
        </div>


        <div class="pl-4 pr-4 pb-5 pt-4" v-if="!readOnly && holdListItem">
            <div class="text-muted font-small text-capitalize">
                To add a comment, select if pros or cons
            </div>

            <pros-cons v-model="type" />

            <div v-if="type">
                <base-textarea :label="`Enter ${type} (0 / 200)`" typeNumber="4" placeholder="Type comment..."
                    v-model="comment_text" :rows="3" />

                <btn-with-border-gradient ref="postComment" @click="createComment">
                    Post Comment
                </btn-with-border-gradient>
            </div>
        </div>
    </div>
</template>

<script>
import CarouselItem from '@/components/shared/carousel/CarouselItem.vue';
import Carousel from '@/components/shared/carousel/Carousel.vue';
import { mapActions, mapGetters } from 'vuex';
import Comment from '@/views/customer/holdlists/comments/Comment.vue';
import { get } from 'lodash';
import ProsCons from '@/views/customer/holdlists/comments/ProsCons.vue';

export default {
    props: {
        holdListItem: {
            type: Object,
        },
        hold_list_id: {
            type: [Number, String],
        },
        comment_type: {
            type: String,
            default: null,
        },
        readOnly: {
            type: Boolean,
            default: false,
        }
    },

    components: {
        Carousel,
        CarouselItem,
        Comment,
        ProsCons,
    },

    data() {
        return {
            type: null,
            activeItem: 0,
            comment_text: '',
            loading: 0,
        }
    },

    computed: {
        ...mapGetters({
            all: 'HoldListComments/getAll',
            pageDetails: 'HoldListComments/getPageDetails',
        }),

        advert() {
            return get(this.holdListItem, 'advert', {});
        },

        advertMedia() {
            return get(this.holdListItem, 'advert.advert_media', []);
        },
    },

    watch: {
        holdListItem() {
            this.init();
        },

        comment_type() {
            this.init();
        }
    },

    methods: {
        ...mapActions({
            store: 'HoldListComments/store',
            index: 'HoldListComments/index',
            clearState: 'HoldListComments/clearState',
        }),

        init() {
            this.clearState()
            this.loadData({ clearState: true });
        },

        loadNextPage() {
            if (this.showLoadMore) {
                this.loadData(this.loadMore);
            }
        },

        go(step) {
            if (this.activeItem + step < 0) {
                this.activeItem = this.advertMedia.length - 1;
            } else if (this.activeItem + step > this.advertMedia.length - 1) {
                this.activeItem = 0;
            } else {
                this.activeItem += step;
            }
        },

        setType(type) {
            this.type = type;
            setTimeout(() => {
                this.$refs.postComment && this.$refs.postComment.$el.scrollIntoView({
                    behavior: 'smooth'
                });
            }, 0);
        },

        createComment() {
            if (!this.holdListItem || !this.comment_text) return;

            const data = {
                text: this.comment_text,
                type: this.type,
                hold_list_item_id: this.holdListItem.id,
            };

            this.loading = 1;

            this.store(data).then(() => {
                this.comment_text = '';
                this.setType(null);
                this.loading = 0;
            }).catch(error => {
                this.loading = 0;
            })
        },

        loadData(query) {
            let params = {
                paginate: true,
                per_page: 10,
                ...query,
                hold_list_item_id: get(this.holdListItem, 'id'),
                hold_list_id: this.hold_list_id,
                type: this.comment_type,
                relations: 'user'
            };

            this.loading = params.page ? 0 : 5;

            this.index(params)
                .then(response => {
                    this.loading = 0;
                })
                .catch(error => {
                    this.loading = 0;
                })
        }
    },

    created() {
        this.init();
    },
}
</script>

<style></style>