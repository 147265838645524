<template>
    <question-answer>
        <question>
            <div ref="title" class="font-5xl font-thin text-muted"></div>
        </question>


        <answers alignItems="start" :showOptions="showOptions">
            <div class="selected-products">
                <i class="mdi mdi-chevron-left icon font-2xl text-white"></i>
                <div class="products no-scrollbar flex-grow-1">
                    <span
                        @click.prevent="removeProductType(pt.id)"
                        class="item text-truncate font-xs" v-for="pt in productTypes" :key="pt.id">
                        {{ pt.name }}
                    </span>
                </div>
                <i class="mdi mdi-chevron-right icon font-2xl text-white"></i>
            </div>

            <div class="objective-form">
                <base-input
                  noMargin
                  class="m-0"
                  placeholder="begin typing and select to add"
                  reference="product_type"
                  v-model="productTypeText"
                  :typeahead="productTypeConfig"
                  @selection="val => addProductType(val)" />

                <div class="mt-5">
                    <lets-go @click="goNext" text="Get Started" />
                </div>
            </div>
        </answers>
    </question-answer>
</template>

<script>
import TypeIt from "typeit";
import LetsGo from '@/views/customer/getting-started/steps/LetsGo.vue';
import Answers from '@/views/customer/getting-started/steps/partials/Answers.vue';
import Question from '@/views/customer/getting-started/steps/partials/Question.vue';
import QuestionAnswer from '@/views/customer/getting-started/steps/partials/QuestionAnswer.vue';

export default {
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },

    components: {
        Answers,
        Question,
        QuestionAnswer,
        LetsGo
    },

    data() {
        return {
            showOptions: false,
            productTypeText: '',
        }
    },

    computed: {
        productTypeConfig() {
            return {
                url: `product_types/search`,
                options: {
                    name: 'product_type',
                    display: 'name'
                }
            };
        },

        productTypes() {
            return this.value;
        }
    },

    watch: {
    },

    methods: {
        removeProductType(id) {
            const values = this.productTypes.filter(pt => pt.id != id);
            this.$emit('input', values);
        },

        addProductType(val) {
            this.productTypeText = '';

            if (this.productTypes.find(pt => pt.id == val.id)) {
                return;
            }

            this.$emit('input', [...this.productTypes, val]);
        },

        typeHeading() {
            const focus = `<span class="font-5xl text-primary">products</span>`;

            const instance = new TypeIt(this.$refs.title, {
                speed: 20,
                startDelay: 100,
                cursor: false,
                afterComplete: async (instance) => {
                    this.showOptions = true;
                },
            })
            .empty()
            .type(`Do you have any specific ${focus} in mind?`)
            .type(`<p class="font-small mt-2 font-italic text-black-fade">Add 1 or more product categories.</p>`)
            .go()
        },

        goNext() {
            if (this.productTypes.length === 0) return;

            this.$emit('get-started');
        }
    },

    mounted() {
        setTimeout(() => {
            this.typeHeading();
        }, 0);
    }
}
</script>