<template>
  <div>
    <comparison-item
        v-for="(item, index) in items"
        :key="index"
        :holdListItem="item"
        :isHoldlistOwner="isHoldlistOwner"
        @unhold="$emit('unhold', $event)"/>
  </div>
</template>

<script>
import ComparisonItem from './ComparisonItem.vue'
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        isHoldlistOwner: {
            type: Boolean,
            default: false
        }
    },

    components: {
        ComparisonItem
    }
}
</script>

<style>

</style>