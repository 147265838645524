<template>
    <div class="related-product d-flex">
        <div class="img mr-4">
            <img class="avatar-md" :src="ad.cover_media.file_url"/>
        </div>

        <div class="info flex-grow-1">
            <h5 class="m-0">{{ ad.name }}</h5>
            <p class="text-truncate text-muted m-0">
                {{ ad.subtitle }}
            </p>

            <div class="other d-flex justify-content-between align-items-center">
                <span class="o-price">$12.99</span>
                <div class="actions">
                    <button class="btn bg-pink text-white elevation-2" type="button">
                        <i class="mdi mdi-plus"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        ad: {
            type: Object,
            required: true
        }
    },

    computed: {
    },

    mounted() {
    }
}
</script>