<template>
    <div class="comment" :class="{ loader: loader }">
        <template v-if="!loader">
            <div class="user-img">
                <img :src="comment.user.image_url" class="img circle mr-2"/>
            </div>
            <div class="cbody flex-grow-1">
                <a href="#" @click.prevent="" class="comment-user">
                    <span class="name mr-1 text-truncate font-small">{{ comment.user.fullname }}</span>
                    <span class="username mr-1 text-truncate font-small">- @{{ comment.user.username }}</span>
                    <span class="date text-truncate font-small">- {{ comment.created_at | shortenedTime }}</span>
                </a>
                <div class="text font-small" :class="{'text-shadow-success': comment.type === TYPE_PROS, 'text-shadow-danger': comment.type === TYPE_CONS }">
                    {{ comment.text }}
                    <span class="icon">
                        <i v-if="comment.type === TYPE_PROS" class="mdi mdi-checkbox-multiple-marked text-success"></i>
                        <i v-if="comment.type === TYPE_CONS" class="mdi mdi-close-box-outline text-danger"></i>
                    </span>
                </div>
            </div>
        </template>

        <template v-if="loader">
            <div class="user-img">
                <div class="img circle mr-2"></div>
            </div>
            <div class="cbody flex-grow-1">
                <a href="#" class="comment-user font-small">
                <span class="name mr-1"></span>
                <span class="username mr-1"></span>
                <span class="date"></span>
                </a>
                <div class="text font-small"></div>
            </div>
        </template>
    </div>
</template>
<script>
import { TYPE_PROS, TYPE_CONS } from '@/constants/holdList.js';

export default {
    props: {
        comment: {
            type: Object,
            default: null,
        },
        loader: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            TYPE_PROS,
            TYPE_CONS,
        }
    }
}
</script>

<style>

</style>